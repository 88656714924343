import { useTranslation } from 'react-i18next';

import { creditStore, hasBalance } from '@/store/credits/state';

import { dataStates } from '@/utils/constants';
import { getEffectiveBalanceKey } from '@/utils/formatters';

/**
 * Component to display the credits consumption estimate or error before submitting a job/session
 * @typedef {Object} CreditsHandlerProps
 * @property {string} type - the type of the job/session
 *
 * @param {CreditsHandlerProps} props
 * @returns {React.ReactElement}
 */
export const CreditsHandler = ({ type }) => {
   const { t } = useTranslation();
   const { credits, dataState } = creditStore.value;

   if (dataState === dataStates.error) {
      return <p className="text-red-500">{t('credits.error')}</p>;
   }

   if (!hasBalance.value) {
      return (
         <p className="text-red-500">{t(`credits.lackingAmount.${getEffectiveBalanceKey(credits?.user)}`, { type })}</p>
      );
   }
   return null;
};
