/**
 * @typedef {import('@/types').JobLogsProps} JobLogsProps
 */
import { useTranslation } from 'react-i18next';

import { jobDetailsSignal } from '@/store/hpcJob/store';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { isPreparing } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';

import { SwwcInformationBar } from '@/swwc';

import { LogNodes } from './LogNodes';

/**
 * Componet that render either the output logs or information bar if no logs yet
 * NOTE: The JSDoc typings here are incorrect, the component being wrapped inside a `withErrorBoundary`
 * @param {JobLogsProps} props
 * @returns {React.ReactElement}
 */
export const JobLogs = withErrorBoundary(({ status, outputRepoIsReady }) => {
   const { t } = useTranslation();
   const { dataState } = jobDetailsSignal.value;

   if (isPreparing(status)) {
      return (
         <SwwcInformationBar
            closable={false}
            label=""
            buttonText=""
            fullWidthMode={false}
            informationText={t('logs.wait')}
            useIcon={true}
            variant="info"
         />
      );
   }

   if (dataState === dataStates.loading) {
      return (
         <div className="flex flex-col col-start-1 col-end-5 md:col-start-2 md:col-end-6">
            <LoadingIndicator />
         </div>
      );
   }

   return <LogNodes status={status} outputRepoIsReady={outputRepoIsReady} />;
});
