import { always, cond, curry, equals, gt, lt, T, when } from 'ramda';

import { jobListActions } from '@/store/listing/actions';

import { isComplete, isFailed, isJobType, isStatusTerminating, isTerminated } from '@/utils/comparators';
import { computeJobStatus, computeTaskUnifiedStatus, jobTypeKeys, stepperTenses } from '@/utils/constants';

export const getLastStepLabel = cond([
   [isComplete, always('complete')],
   [isFailed, always('failed')],
   [isStatusTerminating, always('terminating')],
   [isTerminated, always('terminated')],
   [T, always('finished')],
]);

export const getStepLabel = (job) => when(equals('finished'), () => getLastStepLabel(job));

export const getStepTenses = cond([
   [lt, always(stepperTenses.past)],
   [gt, always(stepperTenses.future)],
   [T, always(stepperTenses.present)],
]);

export const updateJobStatus = (status, job) => {
   if (isJobType(jobTypeKeys.hpc)(job)) {
      job.tasks[0].unifiedStatus = status;
   } else {
      // Remote Desktop sessions are marked as DONE when terminated, unlike HPC jobs
      job.status = status === computeTaskUnifiedStatus.Terminated ? computeJobStatus.Done : status;
   }
   return job;
};

export const updateJob = curry((status, job) => jobListActions.updateJob(updateJobStatus(status, job)));
