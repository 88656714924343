import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { settings, settingsSignal } from '@/store/settings/store';

import { Radio } from './Radio';
import { Section } from './Section';

const link = 'https://www.sw.siemens.com/en-US/trust-center/product-excellence-program/';

export function ProductExcellenceProgram({ isDisclaimer = false }) {
   const client = useClient();

   const { t } = useTranslation();
   const {
      userEntitlementData: { id },
      telemetry: { pepTelemetryEnabled, viewedTelemetry },
   } = settingsSignal.value;

   return (
      <Section label={t('telemetry.pep.label')} className="whitespace-pre-line">
         <p>{t('telemetry.pep.info')}</p>
         <br />
         <p className="relative">
            {t('telemetry.pep.trustCenter')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={link} className="font-bold text-status-blue">
               {t('telemetry.trustCenterLink')}
            </a>
         </p>
         <br />
         <p>{isDisclaimer ? t('telemetry.pep.settings') : t('telemetry.pep.consent')}</p>
         <Radio
            prefix="pep"
            onAgreed={() => settings.updateProductExcellenceProgram(client, id, true)}
            onDecline={() => settings.updateProductExcellenceProgram(client, id, false)}
            agreed={viewedTelemetry ? pepTelemetryEnabled : null}
         />
      </Section>
   );
}
