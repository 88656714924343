import { applyOperation } from 'fast-json-patch';
import { scan, stream } from 'flyd';
import { compose, concat, evolve, has, is, join, mergeDeepRight, when } from 'ramda';

const arrayToPath = when(is(Array), compose(concat('/'), join('/')));
const formatJsonPatch = evolve({
   path: arrayToPath,
});

const applyJsonPatch = (currentState, patch) =>
   applyOperation(currentState, formatJsonPatch(patch), true, false).newDocument;

const applyProposal = (currentState, proposal) => {
   if (is(Function, proposal)) {
      return proposal(currentState);
   }
   if (has('op', proposal)) {
      return applyJsonPatch(currentState, proposal);
   }
   return mergeDeepRight(currentState, proposal);
};

const learn =
   (nap, actions) =>
   (startState, { context, proposal }) => {
      const proposals = is(Array, proposal) ? proposal : [proposal];
      return proposals.reduce((currentState, currentProposal) => {
         const proposedState = applyProposal(currentState, currentProposal);
         return nap({ proposal: currentProposal, actions, currentState, proposedState, context });
      }, startState);
   };

/**
 *
 * Sets up a simple state management 'app'.
 * @param {Function} rawActions - A function which accepts the present function and
 * returns an object of actions
 * @param {Object} initialModel - The control state
 * @param {Function} nap - A function which accepts the most recent change to state (the proposal)
 * the available actions and the currents state and either calls another action or does nothing
 *
 * @returns {Object} The state and actions
 *
 */
export function createApp(
   rawActions, //(present: Presenter<TState>) => TActions,
   initialModel, //TState,
   nap, // NextActionPredicate
) {
   const present = stream();
   const actions = rawActions((proposal, context = null) => present({ proposal, context }));
   const state = scan(learn(nap, actions), initialModel, present);
   return { state, actions };
}
