import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { settings, settingsSignal } from '@/store/settings/store';

import { resetRefinerUser, setRefinerUser } from '@/analytics/refiner';

import { Radio } from './Radio';
import { Section } from './Section';

const link = 'https://www.sw.siemens.com/en-US/trust-center/digital-product-experience/';

export function DigitalProductExperience() {
   const { t } = useTranslation();
   const client = useClient();
   const {
      userEntitlementData: { id },
      telemetry,
   } = settingsSignal.value;

   const onAgreed = () => {
      settings.updateDigitalProductExperience(client, id, true);
      setRefinerUser();
   };

   const onDecline = () => {
      resetRefinerUser();
      settings.updateDigitalProductExperience(client, id, false);
   };

   return (
      <Section label={t('telemetry.dpe.label')}>
         <p>{t('telemetry.dpe.info')}</p>
         <br />
         <p className="relative">
            {t('telemetry.dpe.trustCenter')}{' '}
            <a target="_blank" rel="noopener noreferrer" href={link} className="font-bold text-status-blue">
               {t('telemetry.trustCenterLink')}
            </a>
         </p>
         <br />
         <p>{t('telemetry.dpe.consent')}</p>
         <Radio prefix="dpe" onAgreed={onAgreed} onDecline={onDecline} agreed={telemetry.telemetryEnabled} />
      </Section>
   );
}
