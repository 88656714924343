import { computed } from '@preact/signals-core';
import { filter } from 'ramda';

import { isNotDeleted, safeDerivePreparingJobs, safeDeriveRunningSession } from './helpers';
import { store } from './state';

export const existingJobs = computed(() => {
   const jobs = store.value.jobs;
   const filtered = filter(isNotDeleted, jobs);
   return filtered;
});

// This is invoked after an action has run and updated the workspace;
// it is intended for derived data nad follow on actions.
export const warningStates = computed(() => {
   const state = store.value;

   const warnUserHPCJob = safeDerivePreparingJobs(state);
   const runningSessions = safeDeriveRunningSession(state);

   return {
      warnUserRemoteSession: {
         runningSessions: Array.isArray(runningSessions) ? false : runningSessions,
         userWantsToBeWarned: store.value.warnUserRemoteSession.userWantsToBeWarned,
      },
      warnUserHPCJob: Array.isArray(warnUserHPCJob) ? false : warnUserHPCJob,
   };
});
