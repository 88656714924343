import { lensPath, lensProp, set } from 'ramda';

const fullWidthTableLens = lensProp('fullWidthTable');
const dataStateLens = lensPath(['userEntitlementData', 'dataState']);
const idLens = lensPath(['userEntitlementData', 'id']);
const pepTelemetryEnabledLens = lensPath(['telemetry', 'pepTelemetryEnabled']);
const telemetryEnabledLens = lensPath(['telemetry', 'telemetryEnabled']);
const viewedTelemetryLens = lensPath(['telemetry', 'viewedTelemetry']);
const grantedLens = lensProp('granted');
const wmcUrlLens = lensProp('wmcUrl');

export const setFullWidthTable = (value) => set(fullWidthTableLens, value);
export const setDataState = (value) => set(dataStateLens, value);
export const setId = (value) => set(idLens, value);
export const setPepTelemetryEnabled = (value) => set(pepTelemetryEnabledLens, value);
export const setTelemetryEnabled = (value) => set(telemetryEnabledLens, value);
export const setViewedTelemetry = (value) => set(viewedTelemetryLens, value);
export const setGranted = (value) => set(grantedLens, value);
export const setWmcUrl = (value) => set(wmcUrlLens, value);
