// @ts-nocheck
import { signal } from '@preact/signals-core';
import { compose, isEmpty, isNil, mergeDeepRight, propSatisfies, set, tap, unless } from 'ramda';

import { handleSubmitError } from '@/store/common/utils';
import { jobListActions } from '@/store/listing/actions';
import { setError, setErrorState, setLoading, setReady } from '@/store/signals/dataStateUpdaters';

import { computeJobStatus } from '@/utils/constants';
import { updateJob } from '@/utils/jobStatusUtils';

import { trackSubmitNewRemoteSession } from '@/analytics/events';
import { getServices } from '@/services/services';

import { dataStateLens, detailsLens, detailsSubmissionTemplateLens, formLens } from './lenses';
import { model } from './model';
import { createJobInput, getFieldError, handleDataState, setTemplate } from './utils';

export const remoteDesktopSignal = signal(model);

export const remoteDesktopStore = {
   safeSetTemplate: unless(propSatisfies(isNil, 'remote_desktop'), (template) =>
      setTemplate(template, remoteDesktopSignal),
   ),
   initializeVersion: (version = '') => {
      const currentState = remoteDesktopSignal.value;

      const updatedForm = mergeDeepRight(currentState.form, {
         version: {
            value: version,
            error: isEmpty(version),
         },
      });

      remoteDesktopSignal.value = set(formLens, updatedForm, currentState);
   },
   changeFormValue: ({ key, value }) => {
      const currentState = remoteDesktopSignal.value;

      const updatedForm = mergeDeepRight(currentState.form, {
         [key]: {
            value,
            error: getFieldError(key, value),
         },
      });

      remoteDesktopSignal.value = compose(
         set(dataStateLens, handleDataState(currentState.dataState)(updatedForm)),
         set(formLens, updatedForm),
         set(detailsLens, currentState.details),
      )(currentState);
   },
   runSessionWorkflow: (client, form, applicationTemplates, seconds) => {
      return getServices()
         .createJob(client, createJobInput(form, applicationTemplates))
         .then(tap(jobListActions.addJob))
         .then((job) => {
            trackSubmitNewRemoteSession(window.San, {
               version: form.version,
               jobId: job.resourceId,
               durationInSecs: seconds,
            });
            getServices()
               .submitJob(client, job.resourceId)
               .then(updateJob(computeJobStatus.Queued))
               .catch(handleSubmitError(remoteDesktopSignal)(job));
            return job.resourceId;
         });
   },
   loadJobInfo: (client, resourceId) => {
      remoteDesktopSignal.value = setLoading(remoteDesktopSignal.value);

      return getServices()
         .getDetails(client, resourceId)
         .then(({ jobDefinition }) => {
            remoteDesktopSignal.value = compose(
               set(detailsSubmissionTemplateLens, jobDefinition.computeType),
               setReady,
            )(remoteDesktopSignal.value);
         })
         .catch(() => {
            remoteDesktopSignal.value = setError(remoteDesktopSignal.value);
         });
   },

   clearError: () => {
      remoteDesktopSignal.value = setErrorState(null, remoteDesktopSignal.value);
   },

   clear: () => {
      remoteDesktopSignal.value = model;
   },
};
