// @ts-nocheck
import { always, filter, isNil, path, propSatisfies } from 'ramda';

import { handleSubmitError } from '@/store/common/utils';
import { uploadFiles } from '@/store/files/files';
import { fileStore } from '@/store/files/store';
import { jobListActions } from '@/store/listing/actions';
import { store } from '@/store/listing/state';

import { extractComputeTypeName, findByResourceId, getTask } from '@/utils/accessors';
import { isFinished, isNotNilOrEmpty } from '@/utils/comparators';
import { computeTaskUnifiedStatus, errorTypes } from '@/utils/constants';
import { updateJob, updateJobStatus } from '@/utils/jobStatusUtils';

import { trackSubmitNewHPCJob } from '@/analytics/events';
import { refinerTrackHPCJobSubmission } from '@/analytics/refiner';
import { getServices } from '@/services/services';

const isTerminated = (resourceId) => {
   const job = findByResourceId(resourceId)(store.value.jobs);

   return isFinished(job);
};
const isTerminatedorUndefined = (key, item) => isNil(item) || isTerminated(item[key]);

export const createJob = (client, form, jobTypes, token, durationInSecs) => {
   return getServices()
      .createHPCJob(client, form, jobTypes, token)
      .then((job) => {
         trackSubmitNewHPCJob(window.San, {
            form,
            durationInSecs,
            jobId: job.resourceId,
         });
         refinerTrackHPCJobSubmission();
         const updatedJob = updateJobStatus(computeTaskUnifiedStatus.Creating, job);
         jobListActions.addJob(updatedJob);

         return updatedJob;
      });
};

export const submitJob = (client, jobDetailsSignal) => (job) => {
   if (!isTerminated(job.resourceId)) {
      updateJob(computeTaskUnifiedStatus.Submitting, job);

      return getServices()
         .submitJob(client, job.resourceId)
         .then(updateJob(computeTaskUnifiedStatus.Queued))
         .catch(handleSubmitError(jobDetailsSignal)(job));
   }
};

export const prepareAndUploadFiles = (client, form) => (job) => {
   if (!isTerminatedorUndefined('resourceId', job)) {
      const repositoryId = path(['tasks', '0', 'inputFileRepository', 'id'], job);
      const fileList = filter(propSatisfies(isNotNilOrEmpty, 'name'), [
         form.simulationFile,
         form.macro,
         ...form.otherFiles,
      ]);
      const id = job.resourceId;

      updateJob(computeTaskUnifiedStatus.Uploading, job);

      fileStore.actions.setJobFiles({ id, form });

      return uploadFiles({ client, repositoryId, files: fileList, id, form })
         .catch(() => {
            store.actions.terminateJob(client, job);
            throw new Error(`Failed to upload files for job ${job.resourceId}`, {
               cause: { type: errorTypes.uploadFilesError },
            });
         })
         .then(always(job));
   }
   return Promise.resolve(job);
};

export const buildProductInfo = (productInformation) => ({
   computeTypeName: extractComputeTypeName(productInformation),
   task: getTask(productInformation),
});
