import { effect } from '@preact/signals-core';

import { dataStates, pollDelay } from '@/utils/constants';

import { jobListActions } from './actions';
import { store } from './state';

export const setupPolling = (client) => {
   effect(() => {
      if (store.value.dataState === dataStates.ready) {
         const timer = setTimeout(() => jobListActions.loadJobs(client, true), pollDelay);
         return () => clearTimeout(timer);
      }
   });
};
