// @ts-nocheck
import {
   __,
   any,
   anyPass,
   both,
   complement,
   compose,
   either,
   equals,
   findIndex,
   gt,
   isEmpty,
   isNil,
   isNotNil,
   lt,
   lte,
   path,
   pathOr,
   prop,
   propEq,
   propSatisfies,
   test,
} from 'ramda';

import { findByResourceId, getJobType, getStatus } from './accessors';
import { computeJobStatus, computeTaskUnifiedStatus, errorTypes, forbiddenContent } from './constants';
import { within } from './utils';

export const isFailed = compose(
   within([
      computeTaskUnifiedStatus.Failed,
      computeTaskUnifiedStatus.StartFailed,
      computeJobStatus.Failed,
      computeJobStatus.InsufficientCredits,
      computeJobStatus.Interrupted,
   ]),
   getStatus,
);
export const isPendingOrQueued = compose(
   within([
      computeTaskUnifiedStatus.Pending,
      computeTaskUnifiedStatus.Queued,
      computeJobStatus.Queued,
      computeJobStatus.Pending,
   ]),
   getStatus,
);
export const isTaskFinished = within([
   computeTaskUnifiedStatus.Complete,
   computeTaskUnifiedStatus.Failed,
   computeTaskUnifiedStatus.Terminated,
   computeTaskUnifiedStatus.Interrupted,
   computeTaskUnifiedStatus.Error,
   computeTaskUnifiedStatus.StartFailed,
   computeTaskUnifiedStatus.Terminating,
   computeTaskUnifiedStatus.InsufficientCredits,
]);
export const isRunning = compose(within([computeTaskUnifiedStatus.Executing, computeJobStatus.Running]), getStatus);
export const isInProcess = either(isPendingOrQueued, isRunning);
export const isTimedOut = compose(equals(computeJobStatus.TimedOut), getStatus);
export const isFinished = compose(
   within([
      computeTaskUnifiedStatus.Complete,
      computeTaskUnifiedStatus.Failed,
      computeTaskUnifiedStatus.Terminated,
      computeTaskUnifiedStatus.Interrupted,
      computeTaskUnifiedStatus.Error,
      computeTaskUnifiedStatus.StartFailed,
      computeTaskUnifiedStatus.Terminating,
      computeTaskUnifiedStatus.Deleting,
      computeTaskUnifiedStatus.Deleted,
      computeJobStatus.Done,
      computeJobStatus.Interrupted,
      computeJobStatus.ServiceTerminated,
      computeJobStatus.TimedOut,
      computeJobStatus.Terminated,
      computeJobStatus.InsufficientCredits,
   ]),
   getStatus,
);

const isCreating = compose(equals(computeTaskUnifiedStatus.Creating), getStatus);
const isUploading = compose(equals(computeTaskUnifiedStatus.Uploading), getStatus);
export const isJobUploading = (id) => compose(isUploading, findByResourceId(id));
const isSubmitting = compose(equals(computeTaskUnifiedStatus.Submitting), getStatus);
const isProvisioning = compose(within([computeTaskUnifiedStatus.Queued, computeTaskUnifiedStatus.Starting]), getStatus);
export const isInSubmissionProcess = anyPass([isCreating, isUploading, isSubmitting]);

export const isStatusDeleting = compose(equals(computeTaskUnifiedStatus.Deleting), getStatus);

export const isStatusTerminating = compose(equals(computeTaskUnifiedStatus.Terminating), getStatus);

export const isNilOrEmpty = either(isNil, isEmpty);
export const isNotNilOrEmpty = complement(isNilOrEmpty);

export const hasCredits = compose(lt(0), pathOr(0, ['credits', 'user', 'effectiveBalance']));

const steps = [isCreating, isUploading, isSubmitting, isProvisioning, isRunning, isFinished];

export const getStep = (job) => findIndex((cb) => cb(job), steps);

export const isPreparing = compose(
   within([
      computeTaskUnifiedStatus.Creating,
      computeTaskUnifiedStatus.Uploading,
      computeTaskUnifiedStatus.Submitting,
      computeTaskUnifiedStatus.Pending,
      computeTaskUnifiedStatus.Queued,
      computeTaskUnifiedStatus.Starting,
   ]),
);

export const isComplete = compose(equals(computeTaskUnifiedStatus.Complete), getStatus);
export const isTerminated = compose(equals(computeTaskUnifiedStatus.Terminated), getStatus);
export const isInRange = both(lte(0), gt(100));
export const hasGQLError = compose(propSatisfies(isNotNil, 'error'));
export const isInsufficientError = propEq(errorTypes.insufficientCredits, 'type');
export const isInsufficientCredits = compose(equals(computeTaskUnifiedStatus.InsufficientCredits), getStatus);
export const hasResourceId = compose(any, propEq(__, 'resourceId'));
export const isOutputRepoReady = compose(equals('READY'), path(['data', 'frsFileRepository', 'status']));
export const isAnyFiniteStatus = anyPass([isComplete, isTerminated, isFailed]);
export const propIsNilOrEmpty = propSatisfies(isNilOrEmpty);
export const isJobType = (type) => compose(equals(type), getJobType);
export const isPropNilOrEmpty = (propName) => compose(isNilOrEmpty, prop(propName));
export const hasFileInfo = compose(isNotNilOrEmpty, path(['version', 'file']));
const nameConstraints = [isEmpty, test(forbiddenContent)];
export const isNameInvalid = anyPass(nameConstraints);
export const isFileUploadFailed = propEq(errorTypes.uploadFilesError, 'type');
