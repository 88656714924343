import { useTranslation } from 'react-i18next';

import { jobListActions } from '@/store/listing/actions';

import { getSortedIcon } from '@/utils/tableUtils';

import { SwwcCheckbox } from '@/swwc';

/**
 * Renders the header row with sortable columns and selection checkbox.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {number} props.nSelectedJobs - Number of selected jobs for determining checkbox state.
 * @param {Object} props.table - TanStack table instance providing header data and sorting handlers.
 * @param {Object} props.headers - An object contains column definitions for data table.
 * @returns {React.ReactElement} The table header element.
 *
 * @example
 * <THead nSelectedJobs={selectedJobCount} table={tableInstance} headers={headers} />
 */

export const THead = ({ nSelectedJobs, table, headers }) => {
   const { t } = useTranslation();
   const itemsInPage = table.getRowModel().rows.length;

   const indeterminate = nSelectedJobs > 0 && nSelectedJobs !== itemsInPage;
   const checked = nSelectedJobs > 0 && nSelectedJobs === itemsInPage;

   return (
      <thead>
         {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
               {headerGroup.headers.map(({ column, id }) => {
                  const { header } = column.columnDef;
                  return (
                     <th
                        key={id}
                        className={column.getCanSort() ? 'cursor-pointer' : ''}
                        data-accessor={header}
                        onClick={column.getToggleSortingHandler()}
                     >
                        {header === headers.select.label ? (
                           <SwwcCheckbox
                              checked={checked}
                              indeterminate={indeterminate}
                              onClick={jobListActions.clearSelection}
                           />
                        ) : (
                           <>
                              <span>{t(`labels.${header}`)}</span>
                              {column.getCanSort() && (
                                 <span className={header === headers.status.label ? 'pl-7' : 'pl-1'}>
                                    {getSortedIcon(column)}
                                 </span>
                              )}
                           </>
                        )}
                     </th>
                  );
               })}
            </tr>
         ))}
      </thead>
   );
};
