import { lensPath, lensProp } from 'ramda';

export const nameLens = lensProp('name');
export const simulationFileLens = lensProp('simulationFile');
export const submissionTemplateLens = lensProp('submissionTemplate');

export const versionSelectedLens = lensPath(['version', 'selected']);
export const versionFileLens = lensPath(['version', 'file']);

export const precisionSelectedLens = lensPath(['precision', 'selected']);
export const precisionFileLens = lensPath(['precision', 'file']);

export const errorSimulationFileLens = lensPath(['errors', 'simulationFile']);
export const errorVersionLens = lensPath(['errors', 'version']);
