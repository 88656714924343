import { assoc, compose, prop, when } from 'ramda';

import { getJobType } from '@/utils/accessors';
import { hasGQLError, isInsufficientError } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStates } from '@/utils/constants';
import { updateJob } from '@/utils/jobStatusUtils';

export function pollWithCancel({ fn, validate, baseInterval = 5000, delayIncrease = 1000, maxAttempts = 100 }) {
   let attempts = 0;
   let timer;
   function execute(resolve, reject) {
      fn().then((res) => {
         attempts += 1;
         if (validate(res)) {
            resolve(res);
         } else if (maxAttempts === attempts) {
            reject('Timed out');
         } else {
            timer = setTimeout(execute, baseInterval + attempts * delayIncrease, resolve, reject);
         }
      });
   }
   function clear() {
      clearTimeout(timer);
   }
   return { promise: new Promise(execute), cancel: clear };
}

export const throwWhenError = when(hasGQLError, (res) => {
   throw new Error(res.error);
});

const getUpdatedStatusAndDataState = (isInsufficient) =>
   isInsufficient
      ? { status: computeTaskUnifiedStatus.InsufficientCredits, state: dataStates.ready }
      : { status: computeTaskUnifiedStatus.Failed, state: dataStates.error };

export const getValues = compose(getUpdatedStatusAndDataState, isInsufficientError, prop('cause'));

export const handleSubmitError = (signal) => (job) => (err) => {
   err.cause.jobType = getJobType(job);

   const { status, state } = getValues(err);

   updateJob(status, job);
   // Only display the modal if you are in the details page
   if (window.location.pathname.includes(job.resourceId)) {
      signal.value = compose(assoc('dataState', state), assoc('error', err.cause))(signal.value);
   }
};
