import { __, always, assoc, compose, evolve, ifElse, isNotNil, lensPath, map, prop } from 'ramda';

import { getFilename } from '@/utils/accessors';
import { isNotNilOrEmpty } from '@/utils/comparators';
import { fileExtensions, fileStatus } from '@/utils/constants';
import { formatBytes, formatSpeed, formatTime, getUploadInfoUpdate } from '@/utils/formatters';

export const inputFileStatusLens = lensPath(['fileRepository', 'dataState', 'input']);
export const outputFileStatusLens = lensPath(['fileRepository', 'dataState', 'output']);

const buildFile = compose(assoc('name', __, { upload: { progress: 0, status: fileStatus.progressing } }), prop('name'));
const buildFileInfo = ifElse(isNotNil, buildFile, always(null));
const getOtherFilesInfoFromForm = compose(map(buildFileInfo), prop('otherFiles'));

const getSimulationFile = (form, config, inputFiles) => {
   if (form) {
      return buildFileInfo(form.simulationFile);
   }

   if (config?.sim) {
      return { name: config.sim };
   }

   return { name: getFilename(fileExtensions.simulationFile, inputFiles) };
};

const getJavaMacroFile = (form, config, inputFiles) => {
   if (form) {
      return buildFileInfo(form.macro);
   }

   if (config) {
      return { name: config.macro };
   }
   return { name: getFilename(fileExtensions.macro, inputFiles) };
};

const getOtherFiles = (form, config, inputFiles) => {
   if (form) {
      return getOtherFilesInfoFromForm(form);
   }

   if (isNotNilOrEmpty(config)) {
      return inputFiles.reduce((acc, file) => {
         if (file.filename !== config.sim && file.filename !== config.macro && file.filename !== '.config.json') {
            acc.push({ name: file.filename });
         }
         return acc;
      }, []);
   }

   return [];
};

export const transformUploadInfo = compose(
   evolve({
      loaded: formatBytes,
      total: formatBytes,
      rate: formatSpeed,
      remainingTime: formatTime,
   }),
   getUploadInfoUpdate,
);

export const extractJobFiles = ({ config, inputFiles, form }) => {
   const simulationFile = getSimulationFile(form, config, inputFiles);
   const javaMacro = getJavaMacroFile(form, config, inputFiles);
   const otherFiles = getOtherFiles(form, config, inputFiles);

   const value = {
      sim: simulationFile.name,
   };

   const files = [simulationFile];

   if (javaMacro?.name) {
      value.macro = javaMacro.name;
      files.push(javaMacro);
   }

   files.push(...otherFiles);
   value.files = files;
   return value;
};
