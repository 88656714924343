/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import { auth } from '@simcenter-x/store';
import { path } from 'ramda';

import { settingsSignal } from '@/store/settings/store';

import { isAutomatedTest } from '@/utils/constants';

/**
 * The refiner script is conditionnally loaded based on the Remote Desktop feature flag
 *
 * Once loaded, it will identify the user based on auth / telemetry if said telemetry is enabled by the user.
 * Given that the user can switch on/off the telemetry on the fly, the current solution is to check the state
 * of this value on each event and no-op the listening event if the value is off.
 */

function setRefinerEvent() {
   const {
      telemetry: { telemetryEnabled },
   } = settingsSignal.value;

   if (!isAutomatedTest && telemetryEnabled) {
      window._refinerQueue.push(arguments);
   }
}

export const setRefinerUser = () => {
   const userId = path(['accessTokenData', 'user', 'userId'], auth.state.value);

   const user = {
      id: userId,
      environment: import.meta.env.VITE_REFINER_ENV,
      ecaid: path(['accessTokenData', 'tenant', 'ecaId'], auth.state.value),
      refinerflag: true,
      tenant: window.San?.getHashUserIdentifier(userId),
      analyticsEnv: path(['accessTokenData', 'sws.samauth.tiers', '0', 'analyticsEnv'], auth.state.value),
   };

   setRefinerEvent('identifyUser', user);
};

export const initRefiner = () => {
   window._refinerQueue = window._refinerQueue || [];

   const productKey = import.meta.env.VITE_REFINER_PRODUCT_KEY;
   setRefinerEvent('setProject', productKey);

   const a = document.createElement('script');
   a.type = 'text/javascript';
   a.async = true;
   a.src = 'https://js.refiner.io/v001/client.js';
   const b = document.getElementsByTagName('script')[0];
   b.parentNode.insertBefore(a, b);

   setRefinerUser();
};

export const resetRefinerUser = () => {
   setRefinerEvent('resetUser');
};

export const refinerTrackHPCJobSubmission = () => {
   setRefinerEvent('trackEvent', 'Jobsubmittedevent');
   setRefinerEvent('showForm', 'Job Submission');
};

export const refinerTrackFileDownload = () => {
   setRefinerEvent('trackEvent', 'SimFiledownloadevent');
   setRefinerEvent('showForm', 'Job Simulation');
};

export const refinerTrackRemoteDesktop = () => {
   setRefinerEvent('trackEvent', 'Remotedesktopsessioncreated');
   setRefinerEvent('showForm', 'Remote Desktop Session Creation');
};

export const refinerTrackRemoteTermination = () => {
   setRefinerEvent('trackEvent', 'Remotedesktopsessionterminated');
   setRefinerEvent('showForm', 'Remote Desktop Session Termination');
};

export const refinerTrackRemoteDeletion = () => {
   setRefinerEvent('trackEvent', 'Remotedesktopsessiondeleted');
   setRefinerEvent('showForm', 'Remote Desktop Session Termination');
};
