// @ts-nocheck
/**
 * @typedef {import('@/types').Job} Job
 * @typedef {import('@/types').InsufficientCreditsError} InsufficientCreditsError
 */
import { isNil, nth } from 'ramda';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router';
import { useClient } from 'urql';

import { jobDetailsSignal, jobDetailsStore } from '@/store/hpcJob/store';
import { jobTypesSignal } from '@/store/jobtypes/store';
import { store } from '@/store/listing/state';
import { useStream } from '@/store/useStream';

import { DetailsActions } from '@/modules/common/DetailsActions';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { PageDoesntExist } from '@/modules/common/PageDoesntExist';
import { StatusIcon } from '@/modules/common/StatusIcon';
import { WarnHPCPreparing } from '@/modules/common/WarnUser';
import { FileUploadFailModal } from '@/modules/common/modals/FileUploadFailModal';
import { InsufficientCreditsModal } from '@/modules/common/modals/InsufficientCredits';
import { Credits } from '@/modules/credits/Credits';

import { findByResourceId, getStatus } from '@/utils/accessors';
import { getStep, isFailed } from '@/utils/comparators';
import { dataStates, steps } from '@/utils/constants';

import { JobOutput } from './components/JobOutput';
import { JobInfo } from './components/infos/JobInfo';
import { StepProgress } from './components/stepProgress/StepProgress';

/**
 * @typedef {Object} JobDetailsProps
 * @property {Job} job
 * @property {InsufficientCreditsError} error
 *
 * @param { JobDetailsProps }
 * @returns {React.ReactElement}
 */
const Detail = ({ job, error }) => {
   const taskStatus = getStatus(job);
   //WHEN refreshed during upload, status gets stuck on PENDING which gives a currentStep of -1
   const currentStep = getStep(job);
   const isLastStep = currentStep === steps.length - 1;
   const currentStepLabel = nth(currentStep)(steps);

   return (
      <div className="h-full grid grid-cols-4 grid-rows-[auto_auto_1fr] md:grid-cols-5 md:grid-rows-[auto_1fr]">
         <JobInfo job={job} />
         <div className="col-start-1 col-end-5 md:col-start-2 md:col-end-6">
            <div className="grid grid-cols-3 grid-rows-[auto_auto_auto_auto] md:grid-rows-[auto_auto_auto] items-center gap-4">
               <WarnHPCPreparing className="col-start-1 col-end-5" />
               <div className="flex items-center gap-8 row-start-3 md:row-start-2 col-start-1 col-end-4">
                  <div className="flex gap-4 truncate">
                     <h1 className="text-4xl truncate cursor-help h-12" title={job?.name}>
                        {job?.name}
                     </h1>
                     {isFailed(job) || isLastStep ? <StatusIcon status={taskStatus} /> : null}
                  </div>
                  <DetailsActions job={job} />
               </div>
               <StepProgress job={job} currentStepLabel={currentStepLabel} currentStepIndex={currentStep} />
               <div className="row-start-2 col-start-4 w-72">
                  <Credits />
               </div>
            </div>
         </div>
         <JobOutput job={job} />
         {createPortal(<InsufficientCreditsModal error={error} onClose={jobDetailsStore.clearError} />, document.body)}
         {createPortal(<FileUploadFailModal error={error} onClose={jobDetailsStore.clearError} />, document.body)}
      </div>
   );
};

export const JobDetail = () => {
   const client = useClient();
   const { resourceId } = useParams();
   const workspaceStore = store.value;
   const { error, dataState } = jobDetailsSignal.value;
   const jobTypes = jobTypesSignal.value;
   const job = findByResourceId(resourceId)(workspaceStore.jobs);

   useEffect(() => {
      jobDetailsStore.loadJobInfo(client, resourceId);

      return jobDetailsStore.clear;
   }, []);

   if (dataState === dataStates.error || (isNil(job) && workspaceStore.dataState === dataStates.ready)) {
      return <PageDoesntExist />;
   }

   if (jobTypes.dataState === dataStates.loading || (isNil(job) && workspaceStore.dataState === dataStates.loading)) {
      return <LoadingIndicator />;
   }

   return <Detail job={job} error={error} />;
};
