/**
 * @typedef {import('@/types').FileInputProps} FileInputProps
 * @typedef {import('@/types').InfoFromFile} InfoFromFile
 */
import { t } from 'i18next';
import { compose, concat, ifElse, isNil, join, path, pluck, unless } from 'ramda';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getFileFromTarget } from '@/utils/accessors';
import { hasFileInfo, isNotNilOrEmpty } from '@/utils/comparators';

import { SwwcIcon } from '@/swwc';

import { buttonClasses, fieldClasses, labelClasses } from './styles';

/**
 * Displays an error or hint message for a file input
 * @typedef {Object} ErrorOrHintProps
 * @property {boolean} error - Whether the input has an error
 * @property {InfoFromFile} infoFromFile - A hint message
 * @property {string} errorMessage - An error message
 *
 * @param {ErrorOrHintProps} props
 * @returns {React.ReactElement}
 */
const ErrorOrHint = ({ error, infoFromFile, errorMessage }) => {
   const fileVersion = path(['version', 'file'], infoFromFile);
   const filePrecision = path(['precision', 'file'], infoFromFile);
   const fileInfo = t('job.fileInfo', { version: fileVersion, precision: t(`precision.${filePrecision}`) });

   if (error) {
      return <p className="text-red-500 italic">{errorMessage}</p>;
   }

   // When we extract the version from the file, we also get the precision
   // That is why in this condition we only check for version.file
   if (hasFileInfo(infoFromFile)) {
      return <p className="text-black-500 italic">{fileInfo}</p>;
   }

   return null;
};

const filesNames = compose(join(', '), pluck('name'));

/**
 * Component for a file input
 * @param {FileInputProps} props
 * @returns {React.ReactElement}
 */
export const FileInput = ({
   name,
   label,
   infoFromFile,
   value,
   onChange,
   extension,
   required,
   multiple = false,
   error,
   errorMessage,
   children,
}) => {
   const hiddenFileInput = useRef(null);
   const { t } = useTranslation();
   const inputValue = multiple ? filesNames(value) : value?.name;

   const handleClick = (e) => {
      e.preventDefault();
      hiddenFileInput.current.click();
   };

   const handleMultipleFiles = (e) => {
      const filesList = Array.from(e.target.files);
      if (isNotNilOrEmpty(filesList)) {
         onChange(concat(value, filesList));
      }
   };

   const handleChange = ifElse(
      () => multiple,
      handleMultipleFiles,
      compose(unless(isNil, onChange), getFileFromTarget),
   );

   const handleDeletion = () => {
      hiddenFileInput.current.value = null;
      const newValue = multiple ? [] : null;
      onChange(newValue);
   };

   return (
      <div className="relative gap-2 grid mt-4">
         <label htmlFor={name} className={labelClasses(required, error)}>
            {label}
         </label>
         <div className="flex relative h-11 text-md group max-w-lg" title={inputValue}>
            <button id={name} onClick={handleClick} className={buttonClasses(error)}>
               {t('files.chooseFile')}
            </button>
            <div className={fieldClasses(error)}>
               {' '}
               <span className="truncate">{inputValue}</span>
            </div>
            {isNotNilOrEmpty(inputValue) && (
               <SwwcIcon
                  name="cmdClosePanel24"
                  size="md"
                  onClick={handleDeletion}
                  className="absolute right-2 top-[8px] cursor-pointer"
               />
            )}
         </div>
         <input
            type="file"
            accept={extension}
            ref={hiddenFileInput}
            onChange={handleChange}
            name={name}
            id={name}
            className="hidden"
            multiple={multiple}
         />
         <ErrorOrHint error={error} infoFromFile={infoFromFile} errorMessage={errorMessage} />
         {children}
      </div>
   );
};
