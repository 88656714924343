const requiredStyle = (required) =>
   required
      ? 'pl-4 font-semibold before:content-["*"] before:inline-block before:absolute before:left-0 before:leading-4 before:top-1 before:text-blue-800 before:font-medium before:text-[2rem]'
      : '';

export const labelClasses = (required, error) =>
   `text-md font-medium text-black-800 leading-4 ${requiredStyle(required)} ${
      error ? 'before:text-red-500 text-red-500' : ''
   }`;

const buttonBase =
   'btn-group font-semibold min-w-[10ch] p-2 cursor-pointer flex items-center group-hover:shadow-[0_4px_12px_rgba(0, 0, 0, 0.1)] text-blue-800';
const buttonBorder = 'border border-solid border-blue-800 rounded-sm';
const buttonOutline =
   'focus:after:outline-2 focus:after:outline focus:after:outline-blue-500 focus:outline-2  focus:outline-0 active:after:outline-0';
const buttonError = (error) =>
   error
      ? 'text-red-500 border-red-500 bg-red-500/10 group-hover:bg-red-500/30 active:bg-red-500/30 focus:after:outline-red-500 text-red-500'
      : '';
const buttonBg = 'bg-blue-200 group-hover:bg-blue-200 active:bg-blue-200';
const buttonFocusAfter =
   'focus:after:content-[""] focus:after:absolute focus:after:w-full focus:after:h-full  focus:after:left-0';

export const buttonClasses = (error) =>
   `${buttonBase} ${buttonBorder} ${buttonOutline} ${buttonBg} ${buttonFocusAfter}  ${buttonError(error)}`;

const fieldBorder =
   'border border-solid border-black-400 border-l-0 rounded-r-sm group-hover:border-2 group-hover:border-l-0 btn-group:active:border-2';
const fieldError = (error) => (error ? 'border-red-500 text-red-500 btn-group:active:border-red-500' : '');

export const fieldClasses = (error) =>
   `flex-1 flex items-center p-2 btn-group:active:bg-[#3296b9] w-[15ch] pr-8 ${fieldError(error)} ${fieldBorder}`;
