import { gql } from '@urql/core';
import { path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const query = gql`
   query GetFeatureFlags {
      userFeatureFlags {
         name
         value
      }
   }
`;

export const getFeatureFlags = (client) =>
   client
      .query(query)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'userFeatureFlags']));
