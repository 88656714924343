import { path } from 'ramda';
import { gql } from 'urql';

import { throwWhenError } from '@/store/common/utils';

const query = gql`
   query UserEntitlementData {
      userEntitlementData {
         id
         csaAcceptanceDate
         viewedTelemetry
         telemetryEnabled
         pepTelemetryEnabled
      }
   }
`;

export const getEntitlementData = (client) =>
   client
      .query(query)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'userEntitlementData']));
