import { gql } from '@urql/core';
import { always } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const mutation = gql`
   mutation TerminateJob($input: IdInput!) {
      terminateJob(input: $input) {
         job {
            resourceId
         }
      }
   }
`;

const appSyncMutation = gql`
   mutation TerminateJob($jobId: ID!) {
      terminateJob(jobId: $jobId)
   }
`;

export const terminateJob = (client, job) => {
   return client
      .mutation(mutation, { input: { resourceId: job.resourceId } })
      .toPromise()
      .then(throwWhenError)
      .then(always(job));
};

export const terminateJobGateway = (client, job) => {
   return client
      .mutation(appSyncMutation, { jobId: job.resourceId })
      .toPromise()
      .then(throwWhenError)
      .then(always(job));
};
