// @ts-nocheck
/* eslint-disable complexity */
import { compose, includes, isNil, pick, unless } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { hasBalance } from '@/store/credits/state';
import { jobDetailsStore } from '@/store/hpcJob/store';
import { canSubmit, hpcSubmissionSignal, hpcSubmissionStore } from '@/store/hpcJob/submission/store';
import { jobTypesSignal } from '@/store/jobtypes/store';

import { CreditsHandler } from '@/modules/common/CreditsHandler';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { TextInput } from '@/modules/common/TextInput';
import { FileInput } from '@/modules/common/fileInput/FileInput';
import { MultipleFilesInput } from '@/modules/common/fileInput/MultipleFilesInput';
import { useNavigateWithParams } from '@/modules/common/navigateWithParams';
import { Credits } from '@/modules/credits/Credits';

import { dataStates, fileExtensions, hpcJobTemplate, validSTARCCMVersion } from '@/utils/constants';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { findComputeTypeByName } from '@/utils/jobSubmission';
import { getTimeSpentInSeconds } from '@/utils/utils';

import { SwwcButton } from '@/swwc';

import { ComputeTypes } from './ComputeTypes';
import { VersionAndPrecision } from './VersionAndPrecision';

export const SubmissionForm = () => {
   const { t } = useTranslation();
   const navigate = useNavigateWithParams();
   const client = useClient();

   const state = hpcSubmissionSignal.value;
   const jobTypes = jobTypesSignal.value;

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [jobId, setJobId] = useState(null);

   const onCancel = () => navigate('/');
   const submissionIsDisabled = !(canSubmit.value && hasBalance.value);

   // Analytics variable to calculate time spent to fill the form before submitting it
   const timerStart = useMemo(() => Date.now(), []);

   useEffect(() => {
      jobTypes.dataState === dataStates.ready && hpcSubmissionStore.setInitialTemplate(jobTypes.templates);

      if (jobTypes.dataState === dataStates.ready) {
         sendEmbeddedMessage('populateSimFileField', { cssSelector: "input[name='simulationFile']" });
      }

      return hpcSubmissionStore.clearForm;
   }, [jobTypes.dataState]);

   useEffect(() => {
      if (jobId && includes(hpcJobTemplate.id, window.location.pathname)) {
         navigate(jobId);
      }
   }, [jobId]);

   const handleSubmit = () => {
      setIsSubmitting(true);

      jobDetailsStore
         .runJobWorkflow(client, state, jobTypes.applications.cloud_hpc, getTimeSpentInSeconds(timerStart, Date.now()))
         .then((job) => {
            setJobId(job.resourceId);
         });
   };

   const handleChange = compose(
      unless(isNil, hpcSubmissionStore.changeFormValue('submissionTemplate')),
      findComputeTypeByName(jobTypes.templates?.cloud_hpc),
   );

   return (
      <div>
         <div className="flex justify-end mr-4">
            <Credits />
         </div>
         <div className="mt-4 max-w-screen-md m-auto lg:shadow-md">
            <div className="p-4 bg-status-blue">
               <span className="text-xl font-bold text-white" slot="content">
                  {t('common.newCHPCJob')}
               </span>
            </div>
            <form className="p-4 ">
               <div className="flex flex-col gap-4">
                  <div className="flex gap-4 sm:gap-12 flex-col sm:flex-row w-full justify-between ">
                     <div className="flex flex-col gap-4 w-full max-w-lg mb-4">
                        <TextInput
                           label={t('job.name')}
                           placeholder={t('job.namePlaceholder')}
                           onChange={hpcSubmissionStore.changeFormValue('name')}
                           inputValue={state.name}
                           error={state.errors.name}
                           errorMessage={t('errors.hpcJobSubmission.name')}
                        />
                        <FileInput
                           name="simulationFile"
                           label={t('job.simulationFile')}
                           required
                           infoFromFile={pick(['version', 'precision'], state)}
                           onChange={hpcSubmissionStore.changeSimulationFile(
                              jobTypes.applications?.cloud_hpc,
                              state.version.selected,
                           )}
                           value={state.simulationFile}
                           error={state.errors.simulationFile}
                           errorMessage={
                              state.errors.simulationFile?.key === 'invalidVersion'
                                 ? t('errors.hpcJobSubmission.invalidVersion', {
                                      fileVersion: state.errors.simulationFile.version,
                                      latestSupportedVersion:
                                         state.errors.simulationFile.latestSupported ?? validSTARCCMVersion,
                                   })
                                 : t('errors.hpcJobSubmission.singleFileUpload', {
                                      extension: fileExtensions.simulationFile,
                                   })
                           }
                           extension=".sim"
                        />
                        <FileInput
                           name="javaMacro"
                           label={t('job.mainMacro')}
                           onChange={hpcSubmissionStore.changeFormValue('macro')}
                           value={state.macro}
                           error={state.errors.macro}
                           errorMessage={t('errors.hpcJobSubmission.singleFileUpload', {
                              extension: fileExtensions.macro,
                           })}
                           extension=".java"
                        />
                        <MultipleFilesInput
                           name="otherFiles"
                           label={t('job.otherFiles')}
                           error={state.errors.otherFiles}
                           errorMessage={t('errors.hpcJobSubmission.multipleFileUpload')}
                           onChange={hpcSubmissionStore.changeFormValue('otherFiles')}
                           value={state.otherFiles}
                           multiple
                        />
                     </div>
                     <div className="flex flex-col w-full">
                        <VersionAndPrecision
                           version={state.version}
                           dataState={jobTypes.dataState}
                           applications={jobTypes.applications?.cloud_hpc}
                           selectedPrecision={state.precision.selected}
                        />
                        <ComputeTypes
                           computeTypes={jobTypes.templates?.cloud_hpc}
                           onChange={handleChange}
                           selected={state.submissionTemplate}
                           version={state.version.selected}
                           applications={jobTypes.applications?.cloud_hpc}
                        />
                     </div>
                  </div>
                  <div className="flex flex-col sm:items-end">
                     <div className="h-6 pb-6">
                        <CreditsHandler type={t('jobTypeName.cloud_hpc')} />
                     </div>
                     <div className="flex gap-4">
                        <SwwcButton label={t('commands.cancel')} size="md" onClick={onCancel} />
                        {isSubmitting ? (
                           <span className="block w-20 px-2">
                              <LoadingIndicator size="small" />
                           </span>
                        ) : (
                           <SwwcButton
                              label={t('commands.submit')}
                              disabled={submissionIsDisabled}
                              variant="accent-high-contrast"
                              size="md"
                              onClick={handleSubmit}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};
