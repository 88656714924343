import { forEachObjIndexed, isNil } from 'ramda';

import { jobTypeKeys } from '@/utils/constants';

/**
 * To create events, either use this for more complex ones
 *    let event = San.newEvent(<eventName>);
 *    event.addProperty(<propertyName>, <value>);
 *    event.log();
 *
 * Or this for simple ones:
 *    San.logEvent(<eventName>, <property>)
 */

/**
 * @typedef {string} EventType
 * @enum {EventType}
 */
const events = {
   submitRemote: 'SubmitRemoteDesktopSession',
   submitHPC: 'SubmitCloudHPCJob',
   openRemote: 'OpenRemoteDesktopSession',
   openWMC: 'OpenWebMonitorClient',
   terminateHPC: 'TerminateCloudHPCJob',
   terminateRemote: 'TerminateRemoteDesktopSession',
   deleteHPC: 'DeleteCloudHPCJob',
   deleteRemote: 'DeleteRemoteDesktopSession',
   openWalkMe: 'OpenWalkMe',
};

/**
 * Send an analytics event from an object of event properties
 * @param {*} San
 * @param {EventType} eventType
 * @param {Object} eventProps
 */
const sendEvent = (San, eventType, eventProps) => {
   if (isNil(San)) {
      return;
   }
   const event = San.newEvent(eventType);
   forEachObjIndexed((value, key) => event.addProperty(key, value), eventProps);
   event.log();
};

/* Logs the user submitting a new Cloud HPC Job after its successfull creation
   Information sent:
      - Seconds spent from the arrival on the form page until submission
      - Job ID
      - Submission template name selected
      - Version
      - Precision
*/
export const trackSubmitNewHPCJob = (San, { form, durationInSecs, jobId }) => {
   sendEvent(San, events.submitHPC, {
      jobId,
      clusterSize: form.submissionTemplate.name,
      version: form.version.selected,
      precision: form.precision.selected,
      duration: durationInSecs,
   });
};

/* Logs the user submitting a new Remote Desktop Session after its successfull creation
   Information sent:
      - Seconds spent from the arrival on the form page until submission
      - Job ID
      - Version
*/
export const trackSubmitNewRemoteSession = (San, { version, jobId, durationInSecs }) => {
   sendEvent(San, events.submitRemote, { jobId, duration: durationInSecs, version });
};

// Logs the user deleting a job/session
export const trackDeletion = (San, { jobTypeName, jobId }) => {
   const eventType = jobTypeName === jobTypeKeys.hpc ? events.deleteHPC : events.deleteRemote;
   sendEvent(San, eventType, { jobId });
};

// Logs the user terminating a cloud hpc job or remote desktop session
export const trackTermination = (San, { jobType, jobId }) => {
   const eventType = jobType === jobTypeKeys.hpc ? events.terminateHPC : events.terminateRemote;
   sendEvent(San, eventType, { jobId });
};

// Logs the user connecting to the Web Monitor Client
export const trackOpenWMC = (San, jobId) => {
   sendEvent(San, events.openWMC, { jobId });
};

// Logs the user connecting to a running remote desktop session
export const trackOpenRemote = (San, jobId) => {
   sendEvent(San, events.openRemote, { jobId });
};
