import { gql } from '@urql/core';

export const query = gql`
   query GetFrsFileRepository($computeTaskUrn: String!) {
      frsFileRepository(urn: $computeTaskUrn) {
         id
         status
         files {
            filename
            size
         }
      }
   }
`;
