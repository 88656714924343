import { compose, isNotNil, prop, tap } from 'ramda';

import { fileStore } from '@/store/files/store';

import { sendEmbeddedMessage } from './embeddedMessage';

export const getFileKey = (filename, repositoryId) => filename + repositoryId;

const getFileDownloadInfo = (file, repositoryId) =>
   fileStore.state().downloadInfo[getFileKey(file.filename, repositoryId)];

export const getDownloadStatus = compose(prop('status'), getFileDownloadInfo);

export const getDownloadProgress = compose(prop('progress'), getFileDownloadInfo);

export const sendEmbeddedMessageForEachFile = (message, files, downloadInfo, repositoryId) => {
   files.forEach((file) => {
      const value = downloadInfo[getFileKey(file.filename, repositoryId)];
      if (isNotNil(value)) {
         sendEmbeddedMessage(message, { url: value.url });
      }
   });
};

export const sendTicketMessage = tap((tickets) => sendEmbeddedMessage('fileTransfer/newTickets', { tickets }));
