import { always } from 'ramda';

import { pollWithCancel } from '@/store/common/utils';

import { query } from './query';

export const getOutputFileRepository = (client, computeTaskUrn, validate) => {
   const fn = () => client.query(query, { computeTaskUrn }).toPromise();
   return pollWithCancel({ fn, validate });
};

export const getOutputFileRepositoryGateway = always(Promise.resolve(null));
