import { gql } from '@urql/core';

import { makeUpdateEntitlementInput } from '@/store/settings/utils';

const mutation = gql`
   mutation UpdateUserEntitlementData($input: UpdateUserEntitlementDataInput!) {
      updateUserEntitlementData(input: $input) {
         entitlementDataId
         viewedTelemetry
         csaAcceptanceDate
         telemetryEnabled
      }
   }
`;

const appSyncMutation = gql`
   mutation UpdateUserEntitlementData($id: ID!, $pepTelemetryEnabled: Boolean, $telemetryEnabled: Boolean) {
      updateUserEntitlementData(
         id: $id
         pepTelemetryEnabled: $pepTelemetryEnabled
         telemetryEnabled: $telemetryEnabled
      ) {
         csaAcceptanceDate
         id
         pepTelemetryEnabled
         telemetryEnabled
         viewedTelemetry
      }
   }
`;

export const updateEntitlementData = ({ client, entitlementDataId, pepTelemetryEnabled, telemetryEnabled }) => {
   const input = makeUpdateEntitlementInput(entitlementDataId, pepTelemetryEnabled, telemetryEnabled);
   return client.mutation(mutation, { input }).toPromise();
};

export const updateEntitlementDataGateway = ({ client, entitlementDataId, pepTelemetryEnabled, telemetryEnabled }) => {
   const input = makeUpdateEntitlementInput(entitlementDataId, pepTelemetryEnabled, telemetryEnabled);
   return client
      .mutation(appSyncMutation, {
         id: input.entitlementDataId,
         pepTelemetryEnabled: input.pepTelemetryEnabled,
         telemetryEnabled: input.telemetryEnabled,
      })
      .toPromise();
};
