import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const query = gql`
   query ComputeTaskLog($computeTaskId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
      node(id: $computeTaskId) {
         id
         ... on ComputeTask {
            outputLines(input: { after: $after, first: $first, before: $before, last: $last }) {
               nodes
               pageInfo {
                  hasNextPage
                  startCursor
                  endCursor
               }
            }
         }
      }
   }
`;

export const getLogs = (client, taskId, cursors) =>
   client
      .query(query, { computeTaskId: taskId, ...cursors })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'node', 'outputLines']));

export const getLogsGateway = always(Promise.resolve(null));
