import { lensProp, mergeRight, set } from 'ramda';

import { dataStates } from '@/utils/constants';

const dataStateLens = lensProp('dataState');

export const setLoading = set(dataStateLens, dataStates.loading);
const setRevalidating = set(dataStateLens, dataStates.revalidating);
export const setReady = set(dataStateLens, dataStates.ready);
export const setError = set(dataStateLens, dataStates.error);
export const setLoadingOrRevalidating = (state) => {
   return state.dataState === dataStates.loading ? state : setRevalidating(state);
};
export const setErrorState = (error, state) => mergeRight(state, { dataState: dataStates.error, error });
