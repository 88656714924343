import { gql } from '@urql/core';
import { always, path, when } from 'ramda';

import { hasGQLError } from '@/utils/comparators';

const mutation = gql`
   mutation SubmitJob($input: IdInput!) {
      submitJob(input: $input) {
         job {
            id
            name
            status
            resourceId
            submissionTime
            jobDefinition {
               jobType
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
            }
            tasks {
               id
               name
               status
               unifiedStatus
               inputFileRepository {
                  id
               }
            }
         }
      }
   }
`;

export const submitJob = (client, resourceId) =>
   client
      .mutation(mutation, { input: { resourceId } })
      .toPromise()
      .then(
         when(hasGQLError, (res) => {
            // The submission can fail due to insufficient credits,
            // forcing us to check the error content before throwing
            const error = path(['error', 'graphQLErrors', '0', 'extensions', 'serviceError'], res);
            throw new Error('Submission failed', { cause: { type: error.code, parameters: error.parameters } });
         }),
      )
      .then(path(['data', 'submitJob', 'job']));

export const submitJobGateway = always(Promise.resolve(null));
