import { auth } from '@simcenter-x/store';
import { retryExchange } from '@urql/exchange-retry';
import { cond, pathEq } from 'ramda';
import { cacheExchange, Client, fetchExchange, mapExchange } from 'urql';

import { removeTrailingSlash } from '@/utils/formatters';

import { getApiUrlFromState } from '@/services/services';

const onError = cond([
   [
      pathEq(401, ['response', 'status']),
      () => {
         window.location.href = `${removeTrailingSlash(import.meta.env.BASE_URL)}/unauthorized?code=401`;
      },
   ],
   [
      pathEq(403, ['response', 'status']),
      (r) => {
         console.error('Received 403 from API', r);
      },
   ],
]);

// default values
const options = {
   initialDelayMs: 1000,
   maxDelayMs: 15000,
   randomDelay: true,
   maxNumberAttempts: 2,
   retryIf: (error) => {
      return error.networkError;
   },
};

const unauthorizedExchange = mapExchange({ onError });

export function createGQLClient() {
   return new Client({
      url: getApiUrlFromState(),
      exchanges: [unauthorizedExchange, cacheExchange, retryExchange(options), fetchExchange],
      // NOTE: urql: When the client is created after logging in, there is not (yet) the url in state.
      // This hack makes sure we always get the url from state before sending a request -
      // We need a way to delay creating the client until the auth state is fully initialized.
      // This might mean delaying creation of the client to the `Layout` component where we do the first fetches
      // Or another idea would be to abandon the 'Provider/context' usage totally. The client could be created
      // in the `auth` stream
      fetch: (_, opts) => fetch(getApiUrlFromState(), opts),
      fetchOptions: () => {
         const { accessToken } = auth.state.value;
         return {
            headers: { authorization: `Bearer ${accessToken}` },
         };
      },
      requestPolicy: 'network-only',
   });
}
