import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { hpcSubmissionSignal, hpcSubmissionStore } from '@/store/hpcJob/submission/store';

import { DropdownInput } from '@/modules/common/DropdownInput';

import { precisions } from '@/utils/constants';
import { handleDropdownValueChange, isPrecisionDisabled } from '@/utils/jobSubmission';

/**
 * Component that render the selectable list of versions
 * @typedef {Object} PrecisionSelcetorProps
 * @property {string} selectedPrecision - selected precisin
 * @property {string[]} supportedPrecisions - supported precision
 * @property {number} selectedVersion - selected version
 *
 * @param {PrecisionSelcetorProps} props
 * @returns {React.ReactElement}
 */
export function PrecisionSelector({ selectedPrecision, supportedPrecisions = [], selectedVersion }) {
   const { t } = useTranslation();
   const state = hpcSubmissionSignal.value;
   const versionError = path(['errors', 'version'], state);

   const versionValues = precisions.map((precision) => {
      return {
         label: t(`precision.${precision}`),
         value: precision,
         disabled: versionError || isPrecisionDisabled(selectedVersion, supportedPrecisions, precision),
      };
   });

   return (
      <DropdownInput
         label={t('labels.precision')}
         options={versionValues}
         onValueChange={handleDropdownValueChange(selectedPrecision, hpcSubmissionStore.setPrecision)}
         value={[selectedPrecision]}
         placeholder={t('job.precisionPlaceholder')}
      />
   );
}
