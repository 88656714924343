import { gql } from '@urql/core';
import { path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';
import { isPaginationEnabled } from '@/store/featureFlags/store';

import { marshalJobs, marshalPaginatedJobs } from '@/utils/formatters';

/**
 * CLD-13706
 * - Remove the old query
 * - Remove the oldJobsQuery function
 * - Remove the conditional based on pagination flag and move the content of newJobQuery to getJobs
 */

const oldQuery = gql`
   query GetWorkspace {
      workspace {
         id
         jobs: multiversion_jobs {
            id
            resourceId
            name
            status
            submissionTime
            tasks {
               id
               name
               unifiedStatus
            }
            debitReport {
               amount
               runTime
            }
            jobDefinition {
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
               jobType
            }
         }
      }
   }
`;

const appSyncQuery = gql`
   query GetWorkspace {
      jobs {
         id
         name
         status
         submissionTime
         jobDefinition {
            applications {
               precision
               version
            }
            jobType
         }
         debit {
            amount
            runTime
         }
      }
   }
`;

const paginatedQuery = gql`
   query GetWorkspace {
      paginatedjobs(input: {}) {
         jobs {
            resourceId
            name: jobName
            jobType
            submissionTime
            totalUsage
            runTime
            unifiedStatus
            applications {
               version
               precision
            }
         }
      }
   }
`;

const oldJobsQuery = (client) =>
   client
      .query(oldQuery)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'workspace', 'jobs']));

const paginatedJobsQuery = (client) => {
   return client
      .query(paginatedQuery)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'paginatedjobs', 'jobs']))
      .then(marshalPaginatedJobs);
};

export const getJobs = (client) => {
   const query = isPaginationEnabled.value ? paginatedJobsQuery : oldJobsQuery;
   return query(client);
};

export const getJobsGateway = (client) =>
   client
      .query(appSyncQuery)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'jobs']))
      .then(marshalJobs);
