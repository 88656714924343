import { chain, curry, path, unless } from 'ramda';

const getStartLine = path(['scrollData', 'startLine']);
const startLineDidNotChange = curry((stream, newScrollData) => {
   const newStart = getStartLine(newScrollData);
   const oldStart = getStartLine(stream());

   return newStart === oldStart || (newStart === 0 && oldStart === undefined);
});

export const updateScrollDataWhenChanged = chain(unless, startLineDidNotChange);

class TimerManager {
   constructor() {
      this.timerId = null;
   }
   setTimer(callback, delay) {
      if (this.timerId) {
         // Timer is already set. Wait for it to finish before setting a new one.
         return;
      }

      this.timerId = setTimeout(() => {
         callback();
         this.timerId = null;
      }, delay);
   }

   clearTimer() {
      if (this.timerId) {
         clearTimeout(this.timerId);
         this.timerId = null;
      }
   }
}

export const timerManager = new TimerManager();
