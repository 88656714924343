import { useTranslation } from 'react-i18next';

import { jobTypesSignal } from '@/store/jobtypes/store';
import { existingJobs } from '@/store/listing/computations';
import { store } from '@/store/listing/state';
import { settingsSignal } from '@/store/settings/store';

import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { dataStates } from '@/utils/constants';

import { SwwcInformationBar } from '@/swwc';

import { AppsList } from './apps/AppsList';
import { JobManager } from './jobManager/JobManager';

export const Listing = () => {
   const { dataState } = store.value;
   const jobTypes = jobTypesSignal.value;
   const { fullWidthTable } = settingsSignal.value;
   const { t } = useTranslation();
   const hasNoJob = existingJobs.value.length === 0;

   if (dataState === dataStates.error && hasNoJob) {
      return (
         <div className="grid place-items-center h-full">
            <SwwcInformationBar informationText={t('errors.unavailableJobs')} variant="error" useIcon={true} />
         </div>
      );
   }

   // Only showing the loading spinner before the initial workspace fetchs
   if (jobTypes.dataState === dataStates.loading || dataState === dataStates.loading) {
      return <LoadingIndicator />;
   }

   return hasNoJob ? (
      <AppsList />
   ) : (
      <div className={`flex flex-col mt-2 gap-5 ${fullWidthTable ? 'mx-2' : 'max-w-screen-xl m-auto'}`}>
         <JobManager />
      </div>
   );
};
