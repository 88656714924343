import { isNil, path } from 'ramda';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useClient } from 'urql';

import { jobTypesSignal } from '@/store/jobtypes/store';
import { store } from '@/store/listing/state';
import { remoteDesktopSignal, remoteDesktopStore } from '@/store/remoteDesktop/store';
import { useStream } from '@/store/useStream';

import { RemoteDetailsActions } from '@/modules/common/DetailsActions';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';
import { PageDoesntExist } from '@/modules/common/PageDoesntExist';
import { InsufficientCreditsModal } from '@/modules/common/modals/InsufficientCredits';
import { Credits } from '@/modules/credits/Credits';

import { findByResourceId, getConfigurationDisplay, getTemplateByClusterName } from '@/utils/accessors';
import { dataStates, remoteSessionDefaults } from '@/utils/constants';

import { SwwcInputField } from '@/swwc';

import { RemoteMessage } from './RemoteMessage';

export function JobDetail() {
   const client = useClient();
   const { t } = useTranslation();
   const { sessionId } = useParams();
   const workspaceStore = store.value;
   const { templates, mappings, dataState } = jobTypesSignal.value;
   const remoteStore = remoteDesktopSignal.value;
   const session = findByResourceId(sessionId)(workspaceStore.jobs);

   useEffect(() => {
      remoteDesktopStore.loadJobInfo(client, sessionId);

      return remoteDesktopStore.clear;
   }, []);

   if (
      remoteStore.dataState === dataStates.error ||
      (isNil(session) && workspaceStore.dataState === dataStates.ready)
   ) {
      return <PageDoesntExist />;
   }

   if (isNil(session) || dataState === dataStates.loading) {
      return <LoadingIndicator />;
   }

   const template = getTemplateByClusterName(remoteStore.details?.submissionTemplate?.name)(templates?.remote_desktop);
   const desktopConfiguration = getConfigurationDisplay(remoteStore.dataState)(template);
   const version = mappings[path(['jobDefinition', 'applications', '0', 'version'], session)];

   return (
      <div>
         <div className="flex justify-end">
            <Credits />
         </div>
         <div className="p-4 grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-0 m-auto max-w-screen-md lg:shadow-md">
            <div className="col-span-1 flex flex-col gap-4">
               <h1 className="font-bold text-xl truncate" title={session.name}>
                  {session.name}
               </h1>
               <div className="flex flex-col gap-4 sm:w-[240px]">
                  <SwwcInputField
                     label={t('remote.form.sessionName')}
                     name="name"
                     type="text"
                     value={session.name}
                     errorMessage={t('remote.form.error.sessionName')}
                     readonly
                  />
                  <SwwcInputField
                     label={t('remote.form.idleTime')}
                     value={t('job.runTime.hours', { count: remoteSessionDefaults.idleTime })}
                     readonly
                  />
                  <SwwcInputField label={t('remote.form.simVersion')} value={version ?? t('common.loading')} readonly />
                  <SwwcInputField
                     label={t('remote.form.submissionTemplate.label')}
                     // @ts-ignore
                     value={t(desktopConfiguration.key, desktopConfiguration.options)}
                     readonly
                  />
               </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
               <RemoteDetailsActions job={session} />
               <RemoteMessage session={session} />
            </div>
            {createPortal(
               <InsufficientCreditsModal error={remoteStore.error} onClose={remoteDesktopStore.clearError} />,
               document.body,
            )}
         </div>
      </div>
   );
}
