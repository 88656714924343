// @ts-nocheck
import {
   always,
   compose,
   cond,
   equals,
   find,
   gt,
   isNil,
   isNotNil,
   length,
   path,
   propEq,
   reject,
   T,
   trim,
   unless,
} from 'ramda';

export const updateTrimmedValue = (fn) => compose(fn, trim, path(['target', 'value']));
export const updateValue = (fn) => compose(fn, path(['detail', 'value']));

export const handleDropdownValueChange = (value, fn) =>
   compose(unless(equals(value), fn), path(['detail', 'value', '0']));

export const findComputeTypeByName = (computeTypes) => (name) => find(propEq(name, 'name'), computeTypes ?? []);
export const findSubmissionTemplateById = (templates) => (id) => find(propEq(id, 'id'), templates ?? []);

export const getMessageKey = (selected) =>
   cond([
      [isNil, always(null)],
      [equals(0), always('version.unknown')],
      [gt(selected), always('version.upgrade')],
      [T, always(null)],
   ]);

export const isVersionDisabled = (version, fileVersion) => isNotNil(fileVersion) && version < parseInt(fileVersion);
export const isPrecisionDisabled = (version, supportedPrecisions, precision) =>
   isNotNil(version) && !supportedPrecisions.includes(precision);

const optionsCount = compose(length, reject(propEq(true, 'disabled')));
export const isDropdownDisabled = (options, selected) => {
   const length = optionsCount(options);
   return length === 0 || (length === 1 && isNotNil(selected));
};
