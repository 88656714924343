import { gql } from '@urql/core';
import { path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { sendTicketMessage } from '@/utils/embeddedUtils';

const query = gql`
   mutation IssueFrsFileRepositoryDownloadTickets($input: IssueFrsFileRepositoryTicketsInput!) {
      issueFrsFileRepositoryDownloadTickets(input: $input) {
         serviceUrl
         bucket
         bucketRegion
         accessKey
         secretAccessKey
         sessionToken
         expiration
         prefix
      }
   }
`;

const appSyncQuery = gql`
   mutation IssueFrsFileRepositoryDownloadTickets($repositoryId: ID!) {
      issueFileRepositoryDownloadTickets(repositoryId: $repositoryId) {
         accessKey
         bucket
         bucketRegion
         expiration
         prefix
         secretAccessKey
         serviceUrl
         sessionToken
      }
   }
`;
export const getDownloadTicket = (client, repositoryId) =>
   client
      .mutation(query, { input: { repositoryId } })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'issueFrsFileRepositoryDownloadTickets']))
      .then(sendTicketMessage);

export const getDownloadTicketGateway = (client, repositoryId) =>
   client
      .mutation(appSyncQuery, { repositoryId })
      .toPromise()
      .then(path(['data', 'issueFrsFileRepositoryDownloadTickets']))
      .then(sendTicketMessage);
