import { createColumnHelper } from '@tanstack/react-table';
import { path } from 'ramda';

import { getReadableFileSize } from '@/utils/accessors';
import { fileTableHeaders } from '@/utils/constants';
import { sortByArchives } from '@/utils/tableUtils';

const columnHelper = createColumnHelper();

/**
 * Generate table columns configuration
 * @param {Function} t - Translation function
 * @returns {Object[]} Array of column configurations
 */

export const fileTableColumns = (t) => [
   columnHelper.display({
      id: fileTableHeaders.download.label,
      header: fileTableHeaders.download.label,
      enableSorting: false,
   }),
   columnHelper.accessor((row) => path(fileTableHeaders.filename.path, row), {
      id: fileTableHeaders.filename.label,
      header: fileTableHeaders.filename.label,
      enableSorting: false,
   }),
   columnHelper.accessor((row) => path(fileTableHeaders.type.path, row), {
      id: fileTableHeaders.type.label,
      header: fileTableHeaders.type.label,
      cell: ({ getValue }) => t(getValue()),
      sortingFn: sortByArchives,
      sortUndefined: 'last',
   }),
   columnHelper.accessor((row) => path(fileTableHeaders.size.path, row), {
      id: fileTableHeaders.size.label,
      header: fileTableHeaders.size.label,
      enableSorting: false,
      cell: ({ getValue }) => getReadableFileSize(getValue()),
   }),
];
