// @ts-nocheck
import { signal } from '@preact/signals-core';
import { assoc, compose } from 'ramda';

import { setError, setErrorState, setLoading, setReady } from '@/store/signals/dataStateUpdaters';

import { sendEmbeddedMessage } from '@/utils/embeddedMessage';

import { getServices } from '@/services/services';

import { model } from './model';
import { buildProductInfo, createJob, prepareAndUploadFiles, submitJob } from './utils';

export const jobDetailsSignal = signal(model);

export const jobDetailsStore = {
   loadJobInfo: (client, jobResourceId) => {
      jobDetailsSignal.value = setLoading(jobDetailsSignal.value);

      return getServices()
         .getDetails(client, jobResourceId)
         .then((productInformation) => {
            jobDetailsSignal.value = compose(
               assoc('productInformation', buildProductInfo(productInformation)),
               setReady,
            )(jobDetailsSignal.value);
         })
         .catch(() => {
            jobDetailsSignal.value = setError(jobDetailsSignal.value);
         });
   },
   runJobWorkflow: (client, form, applicationTemplates, seconds) => {
      sendEmbeddedMessage('submittingJob', { inProgress: true });
      const idempotencyToken = crypto.randomUUID();

      const promise = createJob(client, form, applicationTemplates, idempotencyToken, seconds);

      promise
         .then(prepareAndUploadFiles(client, form))
         .then(submitJob(client, jobDetailsSignal))
         .then(() => sendEmbeddedMessage('submittingJob', { inProgress: false }))
         .catch((e) => {
            jobDetailsSignal.value = setErrorState(e.cause, jobDetailsSignal.value);
            console.error(e);
         });

      return promise;
   },
   clearError: () => {
      jobDetailsSignal.value = setErrorState(null, jobDetailsSignal.value);
   },
   clear: () => {
      jobDetailsSignal.value = model;
   },
};
