// @ts-nocheck
import { createColumnHelper } from '@tanstack/react-table';
import { path, pathOr } from 'ramda';

import { StatusTag } from '@/modules/listing/components/StatusTag';

import { headers } from '@/utils/constants';
import { formatRunTime } from '@/utils/formatters';
import {
   getStatusValue,
   sortByCost,
   sortByRunTime,
   sortByStatus,
   sortBySubmitted,
   sortByVersion,
} from '@/utils/tableUtils';

import Icon from './Icon';
import { iconMap } from './IconMap';
import QuickActionsCell from './QuickActionsCell';
import SubmittedTimeCell from './SubmittedTimeCell';
import VersionCell from './VersionCell';

const columnHelper = createColumnHelper();

/**
 * Generates columns configuration for a job listing table.
 *
 * @param {Function} t - Translation function from `i18next` for localization.
 * @param {Object} mappings - Object containing mappings for job types and versions.
 * @returns {Array<Object>} Array of column configurations for the job table.
 *
 * @example
 * const columns = createColumns(t, mappings);
 */

export const createColumns = (t, mappings) => [
   columnHelper.display({
      id: headers.select.label,
      header: headers.select.label,
   }),
   columnHelper.accessor(path(headers.type.path), {
      id: headers.type.label,
      header: headers.type.label,
      cell: ({ getValue }) => (
         <Icon src={iconMap[getValue()]} dataAccessor="type" jobType={t(`jobTypeName.${getValue()}`)} />
      ),
      sortingFn: 'alphanumeric',
   }),
   columnHelper.accessor(path(headers.name.path), {
      id: headers.name.label,
      header: headers.name.label,
      sortingFn: 'text',
   }),
   columnHelper.accessor(path(headers.version.path), {
      id: headers.version.label,
      header: headers.version.label,
      cell: ({ row }) => (
         <VersionCell app={path(['original', 'jobDefinition', 'applications', '0'], row)} mappings={mappings} />
      ),
      sortingFn: sortByVersion,
   }),
   columnHelper.accessor(path(headers.status.path), {
      id: headers.status.label,
      header: headers.status.label,
      cell: ({ row }) => <StatusTag status={getStatusValue(row)} />,
      sortingFn: sortByStatus,
   }),
   columnHelper.accessor(pathOr(0, headers.cost.path), {
      id: headers.cost.label,
      header: headers.cost.label,
      sortingFn: sortByCost,
   }),
   columnHelper.accessor(path(headers.submitted.path), {
      id: headers.submitted.label,
      header: headers.submitted.label,
      cell: ({ getValue }) => <SubmittedTimeCell submissionTime={getValue()} />,
      sortingFn: sortBySubmitted,
   }),
   columnHelper.accessor(pathOr(0, headers.runTime.path), {
      id: headers.runTime.label,
      header: headers.runTime.label,
      cell: ({ row }) => formatRunTime(row.original, t),
      sortingFn: sortByRunTime,
   }),
   columnHelper.display({
      id: headers.quickActions.label,
      header: headers.quickActions.label,
      cell: ({ row }) => <QuickActionsCell job={row.original} />,
   }),
];
