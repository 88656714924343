import { gql } from '@urql/core';
import { path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const appserverQuery = gql`
   query GetSystemJobTemplates {
      workspace {
         systemJobTemplateCatalog {
            systemJobTemplates {
               jobType
               applicationsSupported {
                  type
                  version
                  precision
                  typeHint
               }
               computeTypes {
                  name
                  usableCoreCount
                  hourlyCreditRate
                  typeHint
               }
            }
            displayMappings {
               key
               value
            }
         }
      }
   }
`;

const query = gql`
   query GetSystemJobTemplates {
      jobTemplateCatalog {
         systemJobTemplates: jobTemplates {
            computeTypes: clusterConfigurations {
               usableCoreCount: availableCoreCount
               hourlyCreditRate
               name
               typeHint
            }
            jobType
            applicationsSupported: supportedApplications {
               precision
               typeHint
               type
               version
            }
         }
         displayMappings {
            key
            value
         }
      }
   }
`;

export const getSystemJobTemplates = (client) =>
   client
      .query(appserverQuery)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'workspace', 'systemJobTemplateCatalog']));

export const getSystemJobTemplatesGateway = (client) =>
   client
      .query(query)
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'jobTemplateCatalog']));
