// @ts-nocheck
import { ap, append, compose, ifElse, isNotNil, over, prop, set, tap } from 'ramda';

import { channelEvents, jobsChannel } from '@/store/common/broadcastChannels';
import { setLoadingOrRevalidating, setReady } from '@/store/signals/dataStateUpdaters';

import { hasResourceId } from '@/utils/comparators';
import { computeTaskUnifiedStatus, dataStates, INITIAL_PAGE } from '@/utils/constants';
import { rejectByResourceID } from '@/utils/filters';
import { applyToJobsInWorkspace } from '@/utils/formatters';
import { updateJob } from '@/utils/jobStatusUtils';

import { getServices } from '@/services/services';

import { deletionTrackers, mergeJob, terminationTrackers, verifyDefaults } from './helpers';
import { currentPageLens, jobsLens, selectionLens, userWantsToBeWarnedLens } from './lenses';
import { setupPolling } from './polling';
import { store } from './state';

export const jobListActions = {
   loadJobs: (client, poll = false) => {
      store.value = setLoadingOrRevalidating(store.value);

      return getServices()
         .getJobs(client)
         .then((jobs) => {
            const nextJobs = verifyDefaults(store.value.jobs, jobs);

            store.value = compose(setReady, set(jobsLens, nextJobs))(store.value);

            if (poll) {
               setupPolling(client);
            }
         })
         .catch((err) => {
            console.log(err);
            store.value = {
               ...store.value,
               dataState: dataStates.error,
            };

            if (poll) {
               setupPolling(client);
            }
         });
   },
   deleteJobs: (client, jobs) => {
      const jobResourceIds = jobs.map(prop('resourceId'));
      jobs.forEach(updateJob(computeTaskUnifiedStatus.Deleting));

      return getServices()
         .deleteJobs(client, jobResourceIds)
         .then(() => {
            ap([jobListActions.deselectJob, deletionTrackers], jobs);
            jobs.forEach(updateJob(computeTaskUnifiedStatus.Deleted));
         });
   },
   addJob: (job, emitMessage = true) => {
      store.value = over(jobsLens, (jobs) => [...jobs, job], store.value);

      if (emitMessage) {
         jobsChannel.postMessage({ type: channelEvents.create, job });
      }
   },

   updateJob: (job, emitMessage = true) => {
      store.value = applyToJobsInWorkspace(mergeJob(job))(store.value);

      if (emitMessage) {
         jobsChannel.postMessage({ type: channelEvents.update, job });
      }
   },

   terminateJob: (client, job) => {
      updateJob(computeTaskUnifiedStatus.Terminating, job);

      return getServices()
         .terminateJob(client, job)
         .then(tap(terminationTrackers))
         .then(updateJob(computeTaskUnifiedStatus.Terminated))
         .catch((err) => {
            /**
             * NOTE: We should properly handle that kind of error.
             * If the termination fails the job will hang with the terminating status
             */
            console.error(err);
         });
   },

   selectJob: (job) => {
      store.value = over(
         selectionLens,
         ifElse(hasResourceId(job.resourceId), rejectByResourceID(job.resourceId), append(job)),
         store.value,
      );
   },
   deselectJob: (job) => {
      store.value = over(selectionLens, rejectByResourceID(job.resourceId), store.value);
   },
   clearSelection: () => {
      store.value = set(selectionLens, [], store.value);
   },
   setWarnUserInfo: (value) => {
      sessionStorage.setItem('warnUserRemoteSession', value);
      store.value = set(userWantsToBeWarnedLens, value, store.value);
   },
   getWarnInfo: () => {
      const warnUser = JSON.parse(sessionStorage.getItem('warnUserRemoteSession'));

      if (isNotNil(warnUser)) {
         store.value = set(userWantsToBeWarnedLens, warnUser, store.value);
      }
   },
   test: {
      initWorkspaceState: (data) => {
         store.value = data;
      },
   },
   setCurrentPage: (page) => {
      store.value = set(currentPageLens, page, store.value);
   },
   resetCurrentPage: () => {
      store.value = set(currentPageLens, INITIAL_PAGE, store.value);
   },
};
