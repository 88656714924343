import { dataStates, INITIAL_PAGE } from '@/utils/constants';

export const model = {
   currentPage: INITIAL_PAGE,
   dataState: dataStates.loading,
   jobs: [],
   selection: [],
   displayedJobs: [],
   warnUserRemoteSession: { runningSessions: false, userWantsToBeWarned: true },
   warnUserHPCJob: false,
};
