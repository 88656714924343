import { signal } from '@preact/signals-core';
import { compose, isNotNil } from 'ramda';

import { isNotNilOrEmpty } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';

import { getServices } from '@/services/services';

import {
   setDataState,
   setFullWidthTable,
   setGranted,
   setId,
   setPepTelemetryEnabled,
   setTelemetryEnabled,
   setViewedTelemetry,
   setWmcUrl,
} from './lenses';
import { model } from './model';
import { getSettings, getUpdatedEntitlement } from './utils';

export const settingsSignal = signal(model);

export const settings = {
   initFromStorage: (ls) => {
      const settings = getSettings(ls);
      if (isNotNil(settings)) {
         settingsSignal.value = setFullWidthTable(settings.fullWidthTable)(settingsSignal.value);
      }
   },

   setFullWidthTableSetting: (ls, value) => {
      const settings = getSettings(ls) ?? {};

      settings.fullWidthTable = value;
      ls.setItem('userSettings', JSON.stringify(settings));
      settingsSignal.value = setFullWidthTable(value)(settingsSignal.value);
   },

   getEntitlement: (client) => {
      return getServices()
         .getEntitlementData(client)
         .then(({ id, csaAcceptanceDate, pepTelemetryEnabled, telemetryEnabled, viewedTelemetry }) => {
            const isGranted = viewedTelemetry && isNotNilOrEmpty(csaAcceptanceDate);

            settingsSignal.value = compose(
               setDataState(dataStates.ready),
               setViewedTelemetry(viewedTelemetry),
               setTelemetryEnabled(telemetryEnabled),
               setPepTelemetryEnabled(pepTelemetryEnabled),
               setGranted(isGranted),
               setId(id),
            )(settingsSignal.value);
         })
         .catch(() => {
            settingsSignal.value = compose(
               setDataState(dataStates.error),
               setTelemetryEnabled(false),
               setPepTelemetryEnabled(false),
               setGranted(false),
            )(settingsSignal.value);
         });
   },

   updateEntitlement: (client, id) => {
      return getServices()
         .updateEntitlementData({ client, entitlementDataId: id })
         .then((res) => {
            const { viewedTelemetry, csaAcceptanceDate } = getUpdatedEntitlement(res);
            const isGranted = viewedTelemetry && isNotNilOrEmpty(csaAcceptanceDate);

            settingsSignal.value = compose(
               setViewedTelemetry(viewedTelemetry),
               setGranted(isGranted),
            )(settingsSignal.value);
         })
         .catch(() => {
            settingsSignal.value = compose(setDataState(dataStates.error), setGranted(false))(settingsSignal.value);
         });
   },

   updateProductExcellenceProgram: (client, id, pepTelemetryEnabled) => {
      const San = window.San;

      return getServices()
         .updateEntitlementData({ client, entitlementDataId: id, pepTelemetryEnabled })
         .then((res) => {
            const { viewedTelemetry } = getUpdatedEntitlement(res);

            if (pepTelemetryEnabled) {
               San?.enableOptionalData();
            } else {
               San?.disableOptionalData();
            }

            settingsSignal.value = compose(
               setPepTelemetryEnabled(pepTelemetryEnabled),
               setViewedTelemetry(viewedTelemetry),
            )(settingsSignal.value);
         })
         .catch(() => {
            settingsSignal.value = setPepTelemetryEnabled(false)(settingsSignal.value);
         });
   },

   updateDigitalProductExperience: (client, id, telemetryEnabled) => {
      const San = window.San;

      return getServices()
         .updateEntitlementData({ client, entitlementDataId: id, telemetryEnabled })
         .then(() => {
            if (telemetryEnabled) {
               San?.enableNecessaryData();
            } else {
               San?.disableNecessaryData();
            }

            settingsSignal.value = setTelemetryEnabled(telemetryEnabled)(settingsSignal.value);
         })
         .catch(() => {
            settingsSignal.value = setTelemetryEnabled(false)(settingsSignal.value);
         });
   },

   setWMCBranch: (searchParams) => {
      const wmcbranch = new URLSearchParams(searchParams).get('wmcbranch');

      //build WMC URL
      if (wmcbranch && import.meta.env.DEV) {
         const url = import.meta.env.VITE_WMC_URL.toString().replace('wmc', `${wmcbranch}/index.html#/monitor`);

         console.log('setWMCBranch URL ' + url);
         settingsSignal.value = setWmcUrl(url)(settingsSignal.value);
      }
   },

   getWmcUrl: () => settingsSignal.value.wmcUrl,

   clear: () => {
      settingsSignal.value = model;
   },
};
