/**
 * @typedef {import('@/types').Version} Version
 * @typedef {import('@/types').Application} Application
 */
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';

import { hpcSubmissionSignal, hpcSubmissionStore } from '@/store/hpcJob/submission/store';

import { DropdownInput } from '@/modules/common/DropdownInput';

import { handleDropdownValueChange, isVersionDisabled } from '@/utils/jobSubmission';

/**
 * Component that render the selectable list of precisions
 * @typedef {Object} VersionSelectorProps
 * @property {Version} version - selected version
 * @property {Application[]} applications - list of supported applications
 * @property {string} hintMessage - hint message about product information
 *
 * @param {VersionSelectorProps} prop
 * @returns {React.ReactElement}
 */
export function VersionSelector({ version, applications, hintMessage }) {
   const { t } = useTranslation();
   const state = hpcSubmissionSignal.value;
   const versionError = path(['errors', 'version'], state);

   const versionValues = applications.map(({ marketingVersion }) => {
      return {
         label: marketingVersion,
         value: marketingVersion,
         disabled: versionError || isVersionDisabled(marketingVersion, version.file),
      };
   });

   return (
      <DropdownInput
         label={t('labels.version')}
         options={versionValues}
         onValueChange={handleDropdownValueChange(version.selected, hpcSubmissionStore.setVersion)}
         value={[version.selected]}
         placeholder={t('job.versionPlaceholder')}
         hint={t(hintMessage)}
      />
   );
}
