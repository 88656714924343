// @ts-ignore
import { compose, path, prop, set, when } from 'ramda';

import { jobTypesSignal } from '@/store/jobtypes/store';

import { getTemplateByMarketingVersion } from '@/utils/accessors';

import {
   errorSimulationFileLens,
   errorVersionLens,
   nameLens,
   precisionFileLens,
   precisionSelectedLens,
   submissionTemplateLens,
   versionFileLens,
   versionSelectedLens,
} from './lenses';
import { hpcSubmissionSignal } from './store';
import { appHandlesComputeType, appHandlesPrecision, trimExtension, updateJobNameRequired } from './utils';

export const handleVersionError = (err) => {
   const updates =
      err.message === 'invalidVersion'
         ? { simulationFile: err?.cause ?? true, version: true }
         : { simulationFile: err?.cause ?? true, version: false };

   return compose(
      set(errorSimulationFileLens, updates.simulationFile),
      set(errorVersionLens, updates.version),
   )(hpcSubmissionSignal.value);
};

const handleVersionUpdate = (updates) =>
   when(
      prop('version'),
      compose(set(versionSelectedLens, updates.version.selected), set(versionFileLens, updates.version.file)),
   );

const handlePrecisionUpdate = (updates) =>
   when(
      prop('precision'),
      compose(set(precisionSelectedLens, updates.precision.selected), set(precisionFileLens, updates.precision.file)),
   );

const handleErrorUpdate = (updates) =>
   when(
      prop('errors'),
      compose(
         set(errorSimulationFileLens, updates.errors.simulationFile),
         set(errorVersionLens, updates.errors.version),
      ),
   );

const handleNameUpdate = (value) =>
   when((state) => updateJobNameRequired(state, value), set(nameLens, trimExtension(value)));

export const updatePrecisionAndTemplate = (state) => {
   const { applications } = jobTypesSignal.value;
   const apps = applications?.cloud_hpc ?? [];
   const app = getTemplateByMarketingVersion(state?.version?.selected)(apps);

   let newState = state;

   if (appHandlesPrecision(app, state?.precision?.selected)) {
      newState = set(precisionSelectedLens, app.precision[0])(newState);
   }

   const templateName = path(['submissionTemplate', 'name'], newState);
   if (appHandlesComputeType(app, templateName)) {
      newState = set(submissionTemplateLens, app.computeTypes[0])(newState);
   }

   return newState;
};

export const processSimulationFileUpdates = (updates, value) =>
   compose(
      updatePrecisionAndTemplate,
      handleNameUpdate(value),
      handleErrorUpdate(updates),
      handlePrecisionUpdate(updates),
      handleVersionUpdate(updates),
   );
