import { pathEq } from 'ramda';

import { dataStates } from '@/utils/constants';

import { getServices } from '@/services/services';

import { setupPolling } from './polling';
import { creditStore } from './state';

const hasMigrationError = pathEq('RCT_RCTTenantMigrationInProgressError', [
   'errors',
   0,
   'graphQLErrors',
   0,
   'extensions',
   'serviceError',
   'code',
]);

export const creditActions = {
   loadCredits: (client, poll = false) => {
      creditStore.value = { ...creditStore.value, dataState: dataStates.loading };

      return getServices()
         .getCredits(client)
         .then((credits) => {
            creditStore.value = {
               credits,
               dataState: dataStates.ready,
            };

            if (poll) {
               setupPolling(client);
            }
         })
         .catch((e) => {
            creditStore.value = {
               ...creditStore.value,
               dataState: hasMigrationError(e) ? dataStates.migrating : dataStates.error,
            };

            if (poll) {
               setupPolling(client);
            }
         });
   },
   test: {
      initCreditState: (data) => (creditStore.value = data),
   },
};
