// @ts-nocheck
import { always, compose, endsWith, equals, isNil, path, pathOr, prop, when } from 'ramda';

import { computeJobStatus, computeTaskUnifiedStatus, downCaret, fileTableHeaders, upCaret } from '@/utils/constants';

import { getStatus } from './accessors';

/**
 * Render header label for table columns
 * @param {string} header - Header key
 * @returns {string} Translated header label
 */
export const renderHeaderLabel = (header) => {
   switch (header) {
      case fileTableHeaders.download.label:
         return `commands.${header}`;
      case fileTableHeaders.type.label:
         return `common.${header}`;
      default:
         return `files.${header}`;
   }
};

/**
 * Get sorted icon for table column
 * @param {Object} column - Column object
 * @returns {string|null} Icon for sorting direction
 */
export const getSortedIcon = (column) => {
   if (!column.getIsSorted()) {
      return null;
   }
   return column.getIsSorted() === 'asc' ? upCaret : downCaret;
};

/**
 * From a row, obtain the correct status / unified status of the session / job
 * If the item is a session and status is 'DONE', convert it to 'COMPLETE' to match display and facilitate sorting
 */
export const getStatusValue = compose(
   when(equals(computeJobStatus.Done), always(computeTaskUnifiedStatus.Complete)),
   getStatus,
   prop('original'),
);
export const sortByStatus = (rowA, rowB) => {
   const first = getStatusValue(rowA);
   const second = getStatusValue(rowB);

   return first.localeCompare(second);
};

const getSubmissionTime = path(['original', 'submissionTime']);
export const sortBySubmitted = (a, b) => {
   const first = getSubmissionTime(a);
   const second = getSubmissionTime(b);

   if (isNil(first)) {
      return 1;
   }

   if (isNil(second)) {
      return -1;
   }

   return new Date(first).getTime() - new Date(second).getTime();
};

const getCost = pathOr(0, ['original', 'debitReport', 'amount']);
export const sortByCost = (a, b) => getCost(a) - getCost(b);
const getRunTime = pathOr(0, ['original', 'debitReport', 'runTime']);
export const sortByRunTime = (a, b) => getRunTime(a) - getRunTime(b);
const getApplication = path(['original', 'jobDefinition', 'applications', '0']);
/**
 * Compares both version (original values, not displayed versions)
 * if both are equal, compares precisions
 */
export const sortByVersion = (a, b) => {
   const first = getApplication(a);
   const second = getApplication(b);

   const versionComp = first.version.localeCompare(second.version);

   if (versionComp !== 0) {
      return versionComp;
   }

   return first.precision.localeCompare(second.precision);
};

const getType = path(['original', 'type']);
const isArchive = compose(endsWith('archive'), getType);
/**
 * sorting is in "reverse" side, we want a go after b if a is archive
 * and we want a go before b if b is archive
 * with this, the archives are just after the simulation files
 * then in the initial state the file table, the sort is set "desc"
 * and the user have the archives and then simulation files at the top
 */
export const sortByArchives = (a, b) => {
   if (isArchive(a) && isArchive(b)) {
      return 0;
   }
   if (isArchive(a)) {
      return 1;
   }
   if (isArchive(b)) {
      return -1;
   }
   return getType(a).localeCompare(getType(b));
};
