import { append, compose, ifElse, includes, isNotNil, path, pathOr, without } from 'ramda';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClient } from 'urql';

import { fileStore } from '@/store/files/store';
import { jobDetailsSignal } from '@/store/hpcJob/store';
import { useStream } from '@/store/useStream';

import { withErrorBoundary } from '@/modules/common/ErrorBoundary';
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { isTaskFinished } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';
import { isEmbedded } from '@/utils/embeddedMessage';
import { hasImageOrLoadingPreview, silentDownload } from '@/utils/filesUtils';
import { formatBytes, formatSpeed, formatTime } from '@/utils/formatters';

import {
   SwwcButton,
   SwwcCommandBar,
   SwwcCommandButton,
   SwwcInformationBar,
   SwwcProgressBar,
   SwwcPropertyValue,
   SwwcSearchBox,
} from '@/swwc';

import { FileTable } from './FileTable';

const createDownloadActionText = (action, t) => t('commands.downloadAction', { action: t(`commands.${action}`) });

const handleSearchEvent = compose(fileStore.actions.setSearchTerm, pathOr('', ['target', 'value']));

/**
 * Preview component that loads an image and shows a loading indicator until the image is fully loaded
 * @typedef {Object} PreviewProps
 * @property {string} image - image src
 * @property {string} status - store's state
 *
 * @param {PreviewProps} props
 * @returns {React.ReactElement}
 */
const ImagePreview = ({ image, status }) => {
   const { t } = useTranslation();

   if (status === dataStates.error) {
      return (
         <div className="relative min-h-[600px] flex items-center justify-center">
            <SwwcInformationBar
               informationText={t('files.noPreview')}
               variant="warning"
               useIcon
               closable
               onCloseEvent={fileStore.actions.closePreview}
            />
         </div>
      );
   }

   return (
      <div className="relative min-h-[600px]">
         {status === dataStates.loading ? (
            <div className="absolute top-0 w-full h-full z-10 grid items-center bg-black-50/[.5]">
               <LoadingIndicator size="xlarge" />
            </div>
         ) : null}
         {isNotNil(image) ? (
            <>
               <SwwcButton
                  className="absolute top-0 right-8"
                  onClick={fileStore.actions.closePreview}
                  label={t('files.closePreview')}
                  variant="chromeless"
               />
               <img
                  src={image}
                  onLoad={fileStore.actions.readyPreview}
                  onError={fileStore.actions.setPreviewError}
                  className="m-auto"
                  alt={t('files.previewAltMessage', { filename: image })}
                  aria-live="polite"
               />
            </>
         ) : null}
      </div>
   );
};

/**
 * Component displaying a command bar with options to download and filter files + paginated table for display
 * @typedef {Object} JobFilesProps
 * @property {string} status job unified status
 *
 * NOTE: The JSDoc typings here are incorrect, the component being wrapped inside a `withErrorBoundary`
 * changes the type handling
 * @param {JobFilesProps} props
 * @returns {React.ReactElement}
 */
export const JobFiles = withErrorBoundary(({ status, outputRepoIsReady }) => {
   const client = useClient();
   const { t } = useTranslation();
   const { files, filtered, preview, downloadInfo, downloadPath, overallDownloadInfo } = useStream(fileStore.state);
   // repositoryId, like it is used here, is only an unique id for sending embedded messages,
   // it does not matter if we always use the id of the inputRepo or outputRepo or any other id
   const repositoryId = path(['productInformation', 'task', 'inputFileRepository', 'id'], jobDetailsSignal.value);
   const [selected, setSelected] = useState([]);

   const isSelectionEmpty = selected.length === 0;

   const onSelect = (filename) => () => {
      const updateSelection = ifElse(includes(filename), without([filename]), append(filename));
      setSelected(updateSelection(selected));
   };

   if (!isTaskFinished(status)) {
      return <SwwcInformationBar informationText={t('files.waitCompletion')} useIcon={true} variant="info" />;
   }

   if ((isTaskFinished(status) && files.length === 0) || !outputRepoIsReady) {
      return <LoadingIndicator />;
   }

   const overallDownloadProgress = () => {
      const values = overallDownloadInfo[repositoryId];
      // if download is paused, unfortunately progress is undefined - calculate it manually
      if (values?.status === 'paused') {
         return ((values.receivedBytes / values.totalBytes) * 100).toFixed(0);
      }
      return values?.progress ?? 0;
   };

   const downloadSelection = fileStore.actions.downloadFiles(client, silentDownload);

   const overallDownloadText = () => {
      const values = overallDownloadInfo[repositoryId];
      return `${formatBytes(values?.receivedBytes)} / ${formatBytes(values?.totalBytes)}, ${formatTime(
         values?.time,
      )} left (${formatSpeed(values?.speed)})`;
   };

   return (
      <div className="grow flex flex-col">
         <SwwcCommandBar borderless>
            <div className="flex items-center gap-3">
               <SwwcCommandButton
                  label={t('commands.download')}
                  icon="cmdDownload24"
                  onClick={() => {
                     if (isEmbedded()) {
                        fileStore.actions.downloadFilesEmbedded(
                           client,
                           downloadPath[repositoryId],
                           repositoryId,
                        )(selected);
                     } else {
                        downloadSelection(selected);
                     }
                  }}
                  disabled={isSelectionEmpty}
               />
               {isEmbedded() && (
                  <>
                     <SwwcProgressBar
                        className="w-50"
                        style={{ opacity: isSelectionEmpty ? '0.5' : '1' }}
                        progress={overallDownloadProgress()}
                        hint={overallDownloadText()}
                     />
                     <SwwcCommandButton
                        label={createDownloadActionText('cancel', t)}
                        icon="cmdCancel24"
                        onClick={() => fileStore.actions.cancelDownload(downloadInfo, repositoryId)(selected)}
                        disabled={isSelectionEmpty}
                     />
                     <SwwcCommandButton
                        label={createDownloadActionText('pause', t)}
                        icon="cmdPause24"
                        onClick={() => fileStore.actions.pauseDownload(downloadInfo, repositoryId)(selected)}
                        disabled={isSelectionEmpty}
                     />
                     <SwwcCommandButton
                        label={createDownloadActionText('resume', t)}
                        icon="cmdPlay24"
                        onClick={() => fileStore.actions.resumeDownload(downloadInfo, repositoryId)(selected)}
                        disabled={isSelectionEmpty}
                     />
                  </>
               )}
               <SwwcSearchBox placeholder={`${t('commands.search')}...`} onInput={handleSearchEvent} size="sm" />
            </div>
         </SwwcCommandBar>
         <FileTable
            data={filtered}
            onSelect={onSelect}
            selected={selected}
            onCancel={fileStore.actions.cancelDownload(downloadInfo, repositoryId)}
            onPause={fileStore.actions.pauseDownload(downloadInfo, repositoryId)}
            onResume={fileStore.actions.resumeDownload(downloadInfo, repositoryId)}
         />

         {isEmbedded() && (
            <SwwcPropertyValue label={t('commands.downloadTo')}>
               <span slot="content">
                  <SwwcButton
                     variant="chromeless"
                     label={downloadPath[repositoryId] || t('commands.selectDownloadLocation')}
                     size="xs"
                     onClick={() => fileStore.actions.setDownloadPathEmbedded(repositoryId, downloadPath[repositoryId])}
                  />
                  &nbsp;
               </span>
            </SwwcPropertyValue>
         )}
         {hasImageOrLoadingPreview(preview) ? <ImagePreview image={preview.image} status={preview.state} /> : null}
      </div>
   );
});
