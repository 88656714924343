import { includes } from 'ramda';

import { fileStore } from '@/store/files/store';

import { computeTaskUnifiedStatus } from '@/utils/constants';

import { timerManager } from './utils';

export const nap = ({ actions, currentState, proposedState, context }) => {
   // We will use the nap to trigger another fetch if the logs are polling, but they didn't change
   // in this case it will not scroll so we intervene and trigger another fetch.
   // In order to avoid endlessly fetching, we limit the fetch to trigger only when autoScroll is true (i.e.
   // scrolled to the bottom of the logs) and when the task is running
   if (
      context &&
      currentState.logsHeight === proposedState.logsHeight &&
      proposedState.autoScroll &&
      includes(context.status, [computeTaskUnifiedStatus.Complete, computeTaskUnifiedStatus.Executing]) &&
      fileStore.state().fileRepository.output.status !== 'READY'
   ) {
      timerManager.setTimer(() => actions.fetchLastLines(context.client, context.taskId), 5000);
   } else {
      timerManager.clearTimer();
   }

   return proposedState;
};
