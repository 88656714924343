// @ts-nocheck
import { compose, equals, isEmpty, isNil, isNotNil, mergeDeepLeft, not, path, prop, propEq, unless, when } from 'ramda';

import { findByResourceId, getJobTypeAndResourceId, getStatus } from '@/utils/accessors';
import { isNilOrEmpty } from '@/utils/comparators';
import { computeTaskUnifiedStatus, jobTypeKeys, statusOrder } from '@/utils/constants';
import { isOneJobPreparing, isOneSessionInProcess } from '@/utils/jobUtils';

import { trackDeletion, trackTermination } from '@/analytics/events';
import { refinerTrackRemoteDeletion, refinerTrackRemoteTermination } from '@/analytics/refiner';

export const isNotDeleted = compose(not, equals(computeTaskUnifiedStatus.Deleted), getStatus);

/*
   As we poll the workspace will also handle updates on the frontend exclusively,
   we need to compare jobs on polls to determine if we update the store or keep the values modified by the frontend
*/

const verifyDefaults = (currentJobs, proposedJobs) => {
   // if current workspace has no jobs, overwrite with the proposed workspace
   if (isEmpty(currentJobs)) {
      return proposedJobs;
   }

   /**
    * We iterate over the current list of jobs and compare with the proposed list:
    * - if the proposed job doesn't exist:
    *    - if the current is deleted we remove it from the list
    *    - otherwise keep the current
    * - otherwise compare both jobs to see which is the most "recent" based on status
    */
   const nextJobs = currentJobs.reduce((jobs, currentJob) => {
      const proposedJob = findByResourceId(currentJob.resourceId)(proposedJobs);

      /*
         If the job is not included in the update:
         - If the current job has a deleted status, remove it from the list
         - If the current job does not have a deleted status and had a submission time, remove it from the list as
            it has probably been deleted by another session
         - If the current job does not have a deleted status nor a submission time, it is probably a new job so keep it
      */

      if (isNil(proposedJob)) {
         if (getStatus(currentJob) === computeTaskUnifiedStatus.Deleted || isNotNil(currentJob.submissionTime)) {
            return jobs;
         }

         jobs.push(currentJob);
         return jobs;
      }

      const proposedStatusWeight = statusOrder[getStatus(proposedJob)] ?? 0;
      const currentStatusWeight = statusOrder[getStatus(currentJob)] ?? 0;
      const jobToKeep = proposedStatusWeight >= currentStatusWeight ? proposedJob : currentJob;

      jobs.push(jobToKeep);
      return jobs;
   }, []);

   // We now need to check in the proposed list of there are jobs that did not exist in current, then add them
   proposedJobs.forEach((proposedJob) => {
      if (!findByResourceId(proposedJob.resourceId)(nextJobs)) {
         nextJobs.push(proposedJob);
      }
   });

   return nextJobs;
};

const terminationTrackers = (job) => {
   const jobType = path(['jobDefinition', 'jobType'], job);
   const resourceId = path(['resourceId'], job);
   trackTermination(window.San, { jobType, id: resourceId });

   if (jobType === jobTypeKeys.remote) {
      refinerTrackRemoteTermination();
   }
};

const deletionTrackers = (job) => {
   const { jobTypeName, resourceId } = getJobTypeAndResourceId(job);
   trackDeletion(window.San, { jobTypeName, resourceId });
   if (jobTypeName === jobTypeKeys.remote) {
      refinerTrackRemoteDeletion();
   }
};

const safeDeriveRunningSession = compose(unless(isNilOrEmpty, isOneSessionInProcess), prop('jobs'));
const safeDerivePreparingJobs = compose(unless(isNilOrEmpty, isOneJobPreparing), prop('jobs'));
const mergeJob = (updatedJob) => when(propEq(updatedJob.resourceId, 'resourceId'), mergeDeepLeft(updatedJob));

export {
   deletionTrackers,
   safeDerivePreparingJobs,
   safeDeriveRunningSession,
   terminationTrackers,
   verifyDefaults,
   mergeJob,
};
