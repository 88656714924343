import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const mutation = gql`
   mutation CreateJob($input: CreateJobInput!) {
      createJob(input: $input) {
         job {
            id
            name
            status
            submissionId
            submissionTime
            resourceId
            tasks {
               id
               name
               application
               status
               unifiedStatus
               inputFileRepository {
                  id
               }
            }
            jobDefinition {
               jobType
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
            }
         }
      }
   }
`;

export const createJob = (client, input) =>
   client
      .mutation(mutation, { input })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'createJob', 'job']));

export const createJobGateway = always(Promise.resolve(null));
