import { auth } from '@simcenter-x/store';

import { getCredits } from './credits/getCredits';
import { getEntitlementData } from './entitlement/getEntitlement';
import { updateEntitlementData, updateEntitlementDataGateway } from './entitlement/updateEntitlement';
import { getFeatureFlags } from './featureFlags/getFeatureFlags';
import { getInputFileRepository, getInputFileRepositoryGateway } from './fileRepositories/input';
import { getOutputFileRepository, getOutputFileRepositoryGateway } from './fileRepositories/output';
import { deleteJobs, deleteJobsGateway } from './jobs/deleteJobs';
import { getDetails, getDetailsGateway } from './jobs/getDetails';
import { getJobs, getJobsGateway } from './jobs/getJobs';
import { terminateJob, terminateJobGateway } from './jobs/terminateJob';
import { getLogs, getLogsGateway } from './logs/getLogs';
import { createHPCJob, createHPCJobGateway } from './submission/createHPCJob';
import { createJob, createJobGateway } from './submission/createJob';
import { submitJob, submitJobGateway } from './submission/submitJob';
import { getSystemJobTemplates, getSystemJobTemplatesGateway } from './systemJobTemplates/getSystemJobTemplates';
import { getDownloadTicket, getDownloadTicketGateway } from './tickets/download';
import { uploadFilesTicket, uploadFilesTicketGateway } from './tickets/upload';

const appserverServices = {
   getJobs,
   getCredits,
   getOutputFileRepository,
   getInputFileRepository,
   submitJob,
   createHPCJob,
   createJob,
   getDetails,
   deleteJobs,
   getEntitlementData,
   updateEntitlementData,
   terminateJob,
   getSystemJobTemplates,
   getFeatureFlags,
   uploadFilesTicket,
   getDownloadTicket,
   getLogs,
};

/**
 * All imported methods have the suffix *Gateway while we need to support both appserver and jobs gateway
 * The exposed methods must keep the same name though hence this mapping
 */
const jobsGatewayServices = {
   getJobs: getJobsGateway,
   getCredits,
   getOutputFileRepository: getOutputFileRepositoryGateway,
   getInputFileRepository: getInputFileRepositoryGateway,
   submitJob: submitJobGateway,
   createHPCJob: createHPCJobGateway,
   createJob: createJobGateway,
   getDetails: getDetailsGateway,
   deleteJobs: deleteJobsGateway,
   getEntitlementData,
   updateEntitlementData: updateEntitlementDataGateway,
   terminateJob: terminateJobGateway,
   getSystemJobTemplates: getSystemJobTemplatesGateway,
   getFeatureFlags,
   uploadFilesTicket: uploadFilesTicketGateway,
   getDownloadTicket: getDownloadTicketGateway,
   getLogs: getLogsGateway,
};

export const getServices = () => (auth.state.value.useJobsGateway ? jobsGatewayServices : appserverServices);

export const getApiUrlFromState = () => {
   const state = auth.state.value;

   if (state.useJobsGateway) {
      return state.appSyncUrl;
   }

   return `${state.appServerUrl}/${import.meta.env.VITE_GQL_SUFFIX}`;
};
