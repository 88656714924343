// @ts-nocheck
import {
   all,
   anyPass,
   chain,
   compose,
   cond,
   curry,
   equals,
   gte,
   has,
   includes,
   isEmpty,
   isNil,
   isNotNil,
   lastIndexOf,
   none,
   prop,
   propEq,
   replace,
   slice,
   sortBy,
   test,
   values,
} from 'ramda';

import { isNameInvalid } from '@/utils/comparators';
import { fileExtensions, forbiddenContent } from '@/utils/constants';

const removeExtension = chain(slice(0), lastIndexOf('.'));
const isNotExtension = curry((ext, value) => !value.name.endsWith(fileExtensions[ext]));

const isEmptySize = compose(gte(0), prop('size'));

const isNameForbidden = test(forbiddenContent);
const isFilenameForbidden = compose(isNameForbidden, removeExtension, prop('name'));

const simSyncConstraints = [isNil, isNotExtension('simulationFile'), isEmptySize, isFilenameForbidden];
export const areSyncConstraintsInvalid = anyPass(simSyncConstraints);

const macroConstraints = [isNotExtension('macro'), isEmptySize, isFilenameForbidden];

const isMacroInvalid = ({ value }) => (isNil(value) ? false : anyPass(macroConstraints)(value));
const isTemplateInvalid = ({ value }) => isNil(value);
const isOtherFilesInvalid = ({ value }) => value.some(isFilenameForbidden);

export const validateField = cond([
   [propEq('name', 'key'), compose(isNameInvalid, prop('value'))],
   [propEq('macro', 'key'), isMacroInvalid],
   [propEq('otherFiles', 'key'), isOtherFilesInvalid],
   [propEq('submissionTemplate', 'key'), isTemplateInvalid],
]);

export const trimExtension = compose(replace(/.sim$/, ''), prop('name'));
const hasNoError = compose(all(equals(false)), values, prop('errors'));

/**
 * Enable/Disable the submit button of the job submission form
 * On mount there are no errors since field validation is made on field update
 * So we check if required fields are filled or not as well here
 */
export function allowSubmission(state) {
   return none(isNil, [state.name, state.simulationFile, state.submissionTemplate]) && hasNoError(state);
}

export const updateJobNameRequired = (current, proposed) => {
   return (
      current.simulationFile !== proposed &&
      isNotNil(proposed) &&
      equals(false, current.errors.simulationFile) &&
      isEmpty(current.name)
   );
};

export const appHandlesPrecision = (app, precision) =>
   isNotNil(precision) && has('precision', app) && !includes(precision, app.precision);

export const appHandlesComputeType = (app, templateName) =>
   has('computeTypes', app) && !app.computeTypes.some(propEq(templateName, 'name'));

/**
 * Sort from lowest to highest marketing version
 * @param {import('@/store/jobtypes/model').applicationList} param0
 */
const sortByMarketingVersion = sortBy(prop('marketingVersion'));

/**
 * Determines which version to select from the supported list based on the provided version and these factors:
 * - Select any exact match
 * - If the version is a B release and this is not supported but A release is: select A release
 * - Otherwise, select the closest higher version available
 * @param {import('@/store/jobtypes/model').applicationList} sortedApplications
 *    Application list sorted by marketing version
 * @param {number} version The version to compare against
 * @returns {number} selected version
 */
export const selectVersion = (sortedApplications, version) => {
   for (const { marketingVersion } of sortedApplications) {
      if (marketingVersion === version) {
         return marketingVersion;
      }

      if (!Number.isInteger(version) && parseInt(version) === marketingVersion) {
         return marketingVersion;
      }

      if (marketingVersion > version) {
         return marketingVersion;
      }
   }

   return null;
};

export const decideVersion =
   (applications, currentSelection) =>
   ({ version, precision }) => {
      const sortedApplications = sortByMarketingVersion(applications);
      if (version > sortedApplications[sortedApplications.length - 1].marketingVersion) {
         throw new Error('invalidVersion', {
            cause: {
               version,
               latestSupported: sortedApplications[sortedApplications.length - 1].marketingVersion,
               key: 'invalidVersion',
            },
         });
      }
      // We must always choose the 'closest newer' version.
      const selected = selectVersion(sortedApplications, version);
      const newSelected = isNotNil(currentSelection) ? Math.max(currentSelection, selected) : selected;
      return {
         version: { selected: newSelected, file: version },
         precision: { selected: precision, file: precision },
         errors: { simulationFile: false, version: false },
      };
   };
