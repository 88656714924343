/**
 * @typedef {import('@/types').Application} Application
 */
import { isNotNil, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';

import { jobTypesSignal } from '@/store/jobtypes/store';

import { DropdownInput } from '@/modules/common/DropdownInput';
import { withErrorBoundary } from '@/modules/common/ErrorBoundary';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { handleDropdownValueChange } from '@/utils/jobSubmission';

/**
 * @typedef {Object} ComputeType
 * @property {string} name - The name of the compute type.
 * @property {number} hourlyCreditRate - The hourly credit rate of the compute type.
 */

/**
 * List of computeTypes (clusters) selectable by the user.
 * @typedef {Object} ComputeTypesProps
 * @property {ComputeType[]} [computeTypes] - The list of compute types.
 * @property {() => void} onChange - The function to call when the selection changes.
 * @property {ComputeType} selected - The selected compute type.
 * @property {number} version - selected version
 * @property {Application[]} applications - list of supported applications
 *
 * @param {ComputeTypesProps} props
 * @returns {React.ReactElement}
 */
export const ComputeTypes = withErrorBoundary(({ computeTypes = [], onChange, selected, version, applications }) => {
   const { t } = useTranslation();
   const { mappings } = jobTypesSignal.value;
   const template = version ? getTemplateByMarketingVersion(version)(applications) : null;

   const isDisabled = (name) => isNotNil(template) && !template.computeTypes.some(propEq(name, 'name'));

   const computeTypeValues = computeTypes.map(({ name, hourlyCreditRate }) => {
      const translatedName = t(`computeTypes.${mappings[name]}`);
      const translatedCost = t(`job.computeTypeConsumption`, { rate: hourlyCreditRate });
      const label = `${translatedName} (${translatedCost}).`;
      return { label: label, value: name, disabled: isDisabled(name) };
   });

   return (
      <DropdownInput
         label={t('job.submissionTemplate')}
         options={computeTypeValues}
         onValueChange={handleDropdownValueChange(selected?.name, onChange)}
         value={[selected?.name]}
      />
   );
});
