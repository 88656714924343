import { config } from '@simcenter-x/store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'urql';

import { getAppServerFromParams } from '@/store/common/urls';

import App from '@/App';
import { createGQLClient } from '@/services/gqlClient';

import './i18n';

import './index.css';
import '@com.siemens.plm.horizon/swwc-core-styles/css/__obsolete/swwc-icons--all.css';

async function renderApp() {
   const appServerOverride = getAppServerFromParams(new URLSearchParams(window.location.search));

   config.setData({
      baseUrl: import.meta.env.BASE_URL,
      appServerPrefix: process.env.VITE_APP_SERVER_PREFIX,
      appServerOverride,
      authEndpoint: process.env.VITE_AUTH_ENDPOINT,
      redirectUri: process.env.VITE_FIXED_REDIRECT_URI,
      postLogoutUri: process.env.VITE_POST_LOGOUT_URI,
      clientId: process.env.VITE_CLIENT_ID,
      samAuthUrl: process.env.PUBLIC_SAM_HOST_ENDPOINT,
   });
   const container = document.getElementById('root');
   const root = createRoot(container);
   const client = createGQLClient();
   root.render(
      <Provider value={client}>
         <App />
      </Provider>,
   );
}

renderApp();
