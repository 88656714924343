import { store } from '@/store/listing/state';

import { WarnHPCPreparing, WarnSessionIsInProcess } from '@/modules/common/WarnUser';
import { Credits } from '@/modules/credits/Credits';
import { CommandBar } from '@/modules/listing/components/CommandBar';
import { JobTable } from '@/modules/listing/components/jobTable/JobTable';

export const JobManager = () => {
   const { selection } = store.value;
   return (
      <>
         <WarnHPCPreparing className="flex flex-col" />
         <WarnSessionIsInProcess className="flex flex-col" />

         <div className="flex justify-between items-center">
            <CommandBar selection={selection} />
            <Credits />
         </div>
         <JobTable />
      </>
   );
};
