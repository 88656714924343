import { compose, prop } from 'ramda';

import { jobListActions } from '@/store/listing/actions';

import { log } from '@/utils/utils';

export const jobsChannel = new BroadcastChannel('simx-jobs');

export const channelEvents = {
   create: 'create',
   update: 'update',
};

const handleEvent = ({ type, job }) => {
   switch (type) {
      case channelEvents.create:
         jobListActions.addJob(job, false);
         break;
      case channelEvents.update:
         jobListActions.updateJob(job, false);
         break;
      default:
         log('Unknown channel event type');
   }
};

export const jobMessageListener = compose(handleEvent, prop('data'));
