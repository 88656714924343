// @ts-nocheck
import { always, any, compose, cond, head, isEmpty, pick, prop, set, T, trim, values } from 'ramda';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { isNameInvalid } from '@/utils/comparators';
import { dataStates, jobTypeKeys } from '@/utils/constants';

import { submissionTemplateLens } from './lenses';

const extractTemplate = compose(head, prop('remote_desktop'));

export const setTemplate = (template, remoteDesktopSignal) => {
   remoteDesktopSignal.value = set(submissionTemplateLens, extractTemplate(template), remoteDesktopSignal.value);
};

export function createJobInput(form, applicationTemplates) {
   const idempotencyToken = crypto.randomUUID();
   // NOTE: Temporarily defaulting to the first entry of application templates because:
   // - Remote desktop does not support version choice yet
   // - Current initial/default form version does not correspond with the remote desktop apps
   const app = getTemplateByMarketingVersion(form.version)(applicationTemplates) || applicationTemplates[0];
   const application = {
      type: app.type,
      version: app.version,
      precision: app.precision[0],
      typeHint: app.typeHint,
   };
   return {
      name: form.name.value,
      jobDefinition: {
         jobType: jobTypeKeys.remote,
         applications: [application],
         computeType: pick(['name', 'typeHint'], form.submissionTemplate.value),
      },
      idempotencyToken,
   };
}

const hasError = any(prop('error'));
const hasEmptyValue = any(compose(isEmpty, prop('value')));
export const handleDataState = (currentDataState) =>
   compose(
      cond([
         [hasError, always(dataStates.error)],
         [hasEmptyValue, always(currentDataState)],
         [T, always(dataStates.ready)],
      ]),
      values,
   );

export const getFieldError = (key, value) => {
   switch (key) {
      case 'name':
         return compose(isNameInvalid, trim)(value);
      case 'version':
         return isEmpty(value);
      default:
         return null;
   }
};
