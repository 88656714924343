import React from 'react';

import { isDropdownDisabled } from '@/utils/jobSubmission';

import { SwwcDropdown } from '@/swwc';

/**
 * Component for a DropdownInput
 * @param {import('@/types').DropdownInputProps} props
 * @returns {React.ReactElement}
 */
export const DropdownInput = ({
   label,
   options,
   required = true,
   onValueChange,
   value,
   placeholder,
   hint,
   className = 'flex flex-col max-w-lg gap-0.5 mb-[1.6rem]',
}) => {
   return (
      <div className={className}>
         <SwwcDropdown
            selectionMode="single"
            label={label}
            options={options}
            required={required}
            onValueChange={onValueChange}
            value={value}
            placeholder={placeholder}
            hint={hint}
            disabled={isDropdownDisabled(options, value[0])}
         />
      </div>
   );
};
