import { always, any, both, compose, cond, ifElse, T, unless } from 'ramda';

import {
   isFinished,
   isInProcess,
   isInSubmissionProcess,
   isJobType,
   isRunning,
   isStatusDeleting,
} from '@/utils/comparators';
import { jobTypeKeys, quickActions } from '@/utils/constants';
import { createUrl } from '@/utils/formatters';

export const goToDetails = (navigate) => unless(isStatusDeleting, compose(navigate, createUrl));

const connectOrMonitor = ifElse(
   isJobType(jobTypeKeys.remote),
   always(quickActions.connect),
   always(quickActions.monitor),
);

export const getActions = cond([
   [isRunning, (job) => [quickActions.terminate, connectOrMonitor(job)]],
   [isFinished, always([quickActions.delete])],
   [T, always([quickActions.terminate])],
]);

// We want to display the connect button for a remote desktop while the session is queued
// specifically on the detail page
export const getRemoteDetailsActions = cond([
   [isFinished, always([quickActions.delete])],
   [T, always([quickActions.terminate, quickActions.connect])],
]);

export const isOneSessionInProcess = any(both(isJobType(jobTypeKeys.remote), isInProcess));
export const isOneJobPreparing = any(both(isJobType(jobTypeKeys.hpc), isInSubmissionProcess));
