import { always, pick } from 'ramda';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { applications, jobTypeKeys } from '@/utils/constants';

import { createJob } from './createJob';

// an HPC Job needs a task to be setup first before we can send the createJob mutation
export const createHPCJob = (client, form, applicationTemplates, idempotencyToken) => {
   // Find the application template corresponding to the form
   const app = getTemplateByMarketingVersion(form.version.selected)(applicationTemplates);

   const application = {
      type: app.type,
      version: app.version,
      precision: form.precision.selected,
      typeHint: app.typeHint,
   };

   const taskInput = {
      name: form.simulationFile.name,
      application: applications.StarCCMp,
      cmdOptions: [
         '-power',
         '-graphics',
         'mesa_swr',
         '-rgraphics',
         'mesa_swr',
         '-mpi',
         'openmpi',
         '-batch',
         form.macro?.name ?? 'run',
         form.simulationFile.name,
      ],
      launch: true,
   };

   return createJob(client, {
      name: form.name,
      jobDefinition: {
         jobType: jobTypeKeys.hpc,
         applications: [application],
         computeType: pick(['name', 'typeHint'], form.submissionTemplate),
      },
      tasks: taskInput,
      idempotencyToken,
   });
};

export const createHPCJobGateway = always(Promise.resolve(null));
