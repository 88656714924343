// @ts-nocheck
import { auth } from '@simcenter-x/store';
import { isNotNil, path } from 'ramda';

import { settingsSignal } from '@/store/settings/store';

import { isAutomatedTest } from '@/utils/constants';

const sanProductKey = import.meta.env.VITE_SAN_PRODUCT_KEY;
const productVersion = import.meta.env.VITE_REACT_APP_VERSION;
const refinerEnv = import.meta.env.VITE_REFINER_ENV;

export const initAnalytics = () => {
   const {
      telemetry: { telemetryEnabled, pepTelemetryEnabled },
   } = settingsSignal.value;
   const San = window.San;

   if (San && sanProductKey && !isAutomatedTest) {
      if (!telemetryEnabled) {
         San.disableNecessaryData();
      }

      if (!pepTelemetryEnabled) {
         San.disableOptionalData();
      }

      const ecaID = path(['accessTokenData', 'tenant', 'ecaId'], auth.state.value);
      const userID = path(['accessTokenData', 'user', 'userId'], auth.state.value);
      const email = path(['idTokenData', 'email'], auth.state.value);
      const region = path(['accessTokenData', 'sws.samauth.tiers', '0', 'region'], auth.state.value);

      San.setCustomerIdentifier(ecaID);
      San.setUserIdentifier(userID);

      if (isNotNil(region)) {
         San.addSessionEnvInfo('region', region);
      }

      const piiStatus = San.setUserPII(email);

      if (piiStatus !== 0) {
         console.log('Failed to set user PII. Error:', piiStatus);
      }

      if (sanProductKey) {
         San.setProductKey(sanProductKey);
      }

      const useInternalServer = refinerEnv !== 'prod';

      const status = San.initialize(
         'SimcenterX',
         productVersion,
         productVersion,
         San.applicationLanguage_EN_US,
         useInternalServer,
      );

      if (status !== 0) {
         console.log('Failed to initialize Analytics. Error: ' + San.getLastErrorMessage());
      }
   }
};
