import { SwwcIcon } from '@/swwc';

import { disabledClasses } from './styles';

const chevronDirections = {
   up: 'rotate-180',
   left: 'rotate-90',
   right: '-rotate-90',
   bottom: '',
};

export const NavigationButton = ({ onClick, disabled, direction = 'bottom' }) => (
   <button
      disabled={disabled}
      className={`bg-white border-solid border-black-150 border text-xl h-8 w-8 flex justify-center items-center rounded-sm ${disabledClasses(
         disabled,
      )}`}
      data-cy={direction}
      onClick={onClick}
   >
      <SwwcIcon name="cmdDrop24" size="md" className={chevronDirections[direction] ?? ''} />
   </button>
);
