// @ts-nocheck
import { computed, effect, signal } from '@preact/signals-core';
import { compose, curry, equals, head, isNil, lensPath, prop, propSatisfies, set, unless } from 'ramda';

import { getVersionAndPrecisionFromFile } from '@/utils/versionCheck';

import {
   precisionSelectedLens,
   simulationFileLens,
   submissionTemplateLens,
   versionFileLens,
   versionSelectedLens,
} from './lenses';
import { model } from './model';
import { handleVersionError, processSimulationFileUpdates, updatePrecisionAndTemplate } from './stateUpdaters';
import { allowSubmission, areSyncConstraintsInvalid, decideVersion, validateField } from './utils';

export const hpcSubmissionSignal = signal(model);

export const canSubmit = computed(() => allowSubmission(hpcSubmissionSignal.value));

effect(() => {
   const currentState = hpcSubmissionSignal.value;
   const newState = updatePrecisionAndTemplate(currentState);

   if (!equals(newState, currentState)) {
      hpcSubmissionSignal.value = newState;
   }
});

export const hpcSubmissionStore = {
   setInitialTemplate: unless(
      propSatisfies(isNil, 'cloud_hpc'),
      compose(
         (template) => {
            hpcSubmissionSignal.value = set(submissionTemplateLens, template)(hpcSubmissionSignal.value);
         },
         head,
         prop('cloud_hpc'),
      ),
   ),
   changeFormValue: curry((key, value) => {
      const validation = validateField({ key, value });

      const fieldLens = lensPath([key]);
      const errorLens = lensPath(['errors', key]);

      hpcSubmissionSignal.value = compose(set(fieldLens, value), set(errorLens, validation))(hpcSubmissionSignal.value);
   }),

   setVersion: (version) => {
      hpcSubmissionSignal.value = set(versionSelectedLens, parseFloat(version))(hpcSubmissionSignal.value);
   },
   setPrecision: (selected) => {
      hpcSubmissionSignal.value = set(precisionSelectedLens, selected)(hpcSubmissionSignal.value);
   },

   changeSimulationFile: (applications, currentSelection) => (value) => {
      return new Promise((resolve, reject) => {
         if (areSyncConstraintsInvalid(value)) {
            if (isNil(value)) {
               hpcSubmissionSignal.value = set(versionFileLens, null)(hpcSubmissionSignal.value);
            }
            reject('syncConstraints');
         }
         resolve(value);
      })
         .then(getVersionAndPrecisionFromFile)
         .then(decideVersion(applications, currentSelection))
         .then((updates) => {
            hpcSubmissionSignal.value = processSimulationFileUpdates(updates, value)(hpcSubmissionSignal.value);
         })
         .catch((err) => {
            hpcSubmissionSignal.value = handleVersionError(err);
         })
         .finally(() => {
            hpcSubmissionSignal.value = set(simulationFileLens, value)(hpcSubmissionSignal.value);
         });
   },
   clearForm: () => {
      hpcSubmissionSignal.value = model;
   },
};
