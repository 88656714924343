/**
 * @typedef {import('@/types').Version} Version
 * @typedef {import('@/types').Application} Application
 */
import { LoadingIndicator } from '@/modules/common/LoadingIndicator';

import { getTemplateByMarketingVersion } from '@/utils/accessors';
import { dataStates } from '@/utils/constants';
import { getMessageKey } from '@/utils/jobSubmission';

import { PrecisionSelector } from './PrecisionSelector';
import { VersionSelector } from './VersionSelector';

/**
 * Component that render the selectable list of versions and precision +
 * an information message about the current selections
 * @typedef {Object} VersionAndPrecisionProps
 * @property {Version} version
 * @property {string} dataState - job type data state
 * @property {Application[]} applications - list of supported applications
 * @property {string} selectedPrecision - selected precision
 *
 * @param {VersionAndPrecisionProps} props
 * @returns {React.ReactElement}
 */
export function VersionAndPrecision({ version, dataState, applications, selectedPrecision }) {
   if (dataState === dataStates.loading) {
      return <LoadingIndicator size="small" />;
   }

   const supportedPrecisions = getTemplateByMarketingVersion(version.selected)(applications)?.precision;
   const messageKey = getMessageKey(version.selected)(version.file);
   return (
      <div>
         <VersionSelector version={version} applications={applications} hintMessage={messageKey} />
         <PrecisionSelector
            selectedPrecision={selectedPrecision}
            supportedPrecisions={supportedPrecisions}
            selectedVersion={version.selected}
         />
      </div>
   );
}
