import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { query } from './query';

export const getInputFileRepository = (client, urn) =>
   client
      .query(query, { computeTaskUrn: urn })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'frsFileRepository']));

export const getInputFileRepositoryGateway = always(Promise.resolve(null));
